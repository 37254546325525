import React, { FC, ReactElement, ReactNode } from 'react';
import { useState } from 'react';

import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Typography from '@mui/material/Typography';

import ListBlock from './ListBlock';
import ThumbBlock from './ThumbBlock';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme();

export interface WebsitesProps {
  anchor: string,
  handlePageClick: Function,
}

const getPage = (id: string) => {
  switch (id) {
    case 'web-apps':
      return (<>Main Test</>);
      break;
    case 'websites':
      return (<>Main Test</>);
      break;
    case 'animations':
      return (<>Main Test</>);
      break;
    case 'graphic-design':
      return (<>Main Test</>);
      break;
    case 'articles':
      return (<>Main Test</>);
      break;
    case 'about':
      return (<>Main Test</>);
      break;
    case 'apps-in-dev':
      return (<>Main Test</>);
      break;
    case 'nodejs':
      return (<>Main Test</>);
      break;
    case 'other-apps':
      return (<>Main Test</>);
      break;
    case 'javascript-snippets':
      return (<>Main Test</>);
      break;
    case 'wordpress':
      return (<>Main Test</>);
      break;
    case 'actionscript':
      return (<>Main Test</>);
      break;
    case 'java':
      return (<>Main Test</>);
      break;
    case 'aia':
      return (<>Main Test</>);
      break;
    case 'monash':
      return (<>Main Test</>);
      break;
    case 'bhi':
      return (<>Main Test</>);
      break;
    case 'yacmu':
      return (<>Main Test</>);
      break;
    case 'misc-web-design':
      return (<>Main Test</>);
      break;
    case 'st-columbs-church':
      return (<>Main Test</>);
      break;
    case 'minecraft-worlds':
      return (<>Main Test</>);
      break;
    case 'skydeck-alpha':
      return (<>Main Test</>);
      break;
    case 'hyperion-gallery':
      return (<>Main Test</>);
      break;
    case 'hyperion-marooned':
      return (<>Main Test</>);
      break;
    case 'the-citadel':
      return (<>Main Test</>);
      break;
    case 'plaza':
      return (<>Main Test</>);
      break;
    case 'animated-clips':
      return (<>Main Test</>);
      break;
    case 'flyers-brochures':
      return (<>Main Test</>);
      break;
    case 'banners-logos':
      return (<>Main Test</>);
      break;
    case 'illustrations':
      return (<>Main Test</>);
      break;
    case 'home':
    default:
      return (<>Main Test</>);
      break;
  }
};

const handleScroll = (itemID: string) => {
  console.log('handleScroll() called with ' + itemID);
  if (itemID && itemID !== '') {
    const element = document.getElementById(itemID);
    element?.scrollIntoView({ behavior: 'smooth' });
  } else {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }
};

const Websites: FC<WebsitesProps> = ({ anchor, handlePageClick }): ReactElement => {
  const handleListBlockClick = (item: string) => {
    //#todo
  };

  const handleImageBlockClick = (id: number) => {
    console.log('Websites.handleImageBlockClick() called with id=' + id);
  }

  return (
    <>
      <Box sx={{
        width: '90%',
        maxWidth: '1280px',
        mt: '10px',
        mb: '20px',
        ml: 'auto',
        mr: 'auto',
        padding: '20px',
        pt: '60px',
        pb: '60px',
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderRadius: '6px',
      }}>
        <Typography variant="h4" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          Websites
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          Design and development projects for the web<br />
          To view the WordPress theme & plugin source code developed for these websites, visit my Git repositories at:
        </Typography>
        <Box sx={{ maxWidth: '800px', m: '10px auto 0' }}>
          <Link href="https://bitbucket.org/skylightcreative/workspace/repositories" target="_blank" underline="hover"><Avatar alt="Bitbucket" src="./static/images/portfolio/bitbucket.png" sx={{ display: "inline-block" }} /><br />Bitbucket.org</Link>
        </Box>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center', m: '14px 0' }}>
          View by client:
        </Typography>
        <Box>
          <Link color="inherit" title="Forever New Clothing" onClick={() => {handleScroll('forevernew');}}>
            <img className="rounded" src="./static/images/portfolio/forever_new_clothing_logo.jpg" width="50px" height="50px" loading="lazy" />
          </Link>
          &nbsp;
          <Link color="inherit" title="Listening Beyond Hearing" onClick={() => {handleScroll('lbh');}}>
            <img className="rounded" src="./static/images/portfolio/lbh_stamp_50x50.jpg" width="50px" height="50px" loading="lazy" />
          </Link>
          &nbsp;
          <Link color="inherit" title="Skylight Creative" onClick={() => {handleScroll('skylight');}}>
            <img className="rounded" src="./static/images/portfolio/skylight_intro_thumb-150x150.jpg" width="50px" height="50px" loading="lazy" />
          </Link>
          &nbsp;
          <Link color="inherit" title="Australian Institute of Architects" onClick={() => {handleScroll('aia');}}>
            <img className="rounded" src="./static/images/portfolio/australian_institute_of_architects_logo.jpg" width="50px" height="50px" loading="lazy" />
          </Link>
          &nbsp;
          <Link color="inherit" title="Monash University" onClick={() => {handleScroll('monash');}}>
            <img className="rounded" src="./static/images/portfolio/monash_university_logo.jpg" width="50px" height="50px" loading="lazy" />
          </Link>
          &nbsp;
          <Link color="inherit" title="Box Hill Institute" onClick={() => {handleScroll('bhi');}}>
            <img className="rounded" src="./static/images/portfolio/box_hill_institute_logo.jpg" width="50px" height="50px" loading="lazy" />
          </Link>
          &nbsp;
          <Link color="inherit" title="Uniting Church in Australia" onClick={() => {handleScroll('uca');}}>
            <img className="rounded" src="./static/images/portfolio/1631381448198.jpg" width="50px" height="50px" loading="lazy" />
          </Link>
          &nbsp;
          <Link color="inherit" title="Misc Web Projects" onClick={() => {handleScroll('misc');}}>
            <img className="rounded" src="./static/images/portfolio/html5-logo.png" width="50px" height="50px" loading="lazy" />
          </Link>
        </Box>
      </Box>
      <Box id='forevernew' sx={{
        width: '90%',
        maxWidth: '1280px',
        mt: '10px',
        mb: '20px',
        ml: 'auto',
        mr: 'auto',
        padding: '20px',
        pt: '60px',
        pb: '60px',
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderRadius: '6px',
      }}>
        <ListBlock anchor='fn' title='Forever New Clothing'
          description={[
            { type: 'text', value: 'Website designed and developed as Senior Frontend Developer within the Forever New Technical Delivery Team.' },
            { type: 'break' },
            { type: 'text', value: 'Please visit: ' },
            { type: 'break' },
            { type: 'link', url: 'https://www.forevernew.com.au', title: 'Forever New - Australia', value: 'Forever New - Australia' },
            { type: 'break' },
            { type: 'link', url: 'https://www.forevernew.co.nz', title: 'Forever New - New Zealand', value: 'Forever New - New Zealand' },
            { type: 'break' },
            { type: 'link', url: 'https://www.forevernew.co.uk', title: 'Forever New - UK', value: 'Forever New - UK' },
            { type: 'break' },
            { type: 'link', url: 'https://www.evernew.ca', title: 'Ever New - Canada', value: 'Ever New - Canada' },
          ]}
          itemList={[
            {
              title: 'Forever New Clothing Australia website',
              description: 'Developed using React.js frontend and Adobe Commerce (Magento) backend.',
              imageSource: './static/images/portfolio/forever-new-320x320.jpg',
              url: 'https://www.forevernew.com.au',
              target: '_blank',
            },
          ]}
          handleListBlockClick={handleListBlockClick} />
      </Box>
      <Box id='lbh' sx={{
        width: '90%',
        maxWidth: '1280px',
        mt: '10px',
        mb: '20px',
        ml: 'auto',
        mr: 'auto',
        padding: '20px',
        pt: '60px',
        pb: '60px',
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderRadius: '6px',
      }}>
        <ListBlock anchor='lbh' title='Listening Beyond Hearing'
          description={[
            { type: 'text', value: 'Music Education and Resource site.' },
            { type: 'break' },
            { type: 'text', value: 'Please visit: ' },
            { type: 'link', url: 'https://listeningbeyondhearing.com.au', title: 'LBH', value: 'listeningbeyondhearing.com.au' },
          ]}
          itemList={[
            {
              title: 'Listening Beyond Hearing website',
              description: 'Designed and developed in WordPress with WooCommerce and Square Payment integration. Hosted as a Ubuntu and Nginx container on Vultr.',
              imageSource: './static/images/portfolio/lbh_stamp_300x300.jpg',
              url: 'https://listeningbeyondhearing.com.au',
              target: '_blank',
            },
          ]}
          handleListBlockClick={handleListBlockClick} />
      </Box>
      <Box id='skylight' sx={{
        width: '90%',
        maxWidth: '1280px',
        mt: '10px',
        mb: '20px',
        ml: 'auto',
        mr: 'auto',
        padding: '20px',
        pt: '60px',
        pb: '60px',
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderRadius: '6px',
      }}>
        <ListBlock anchor='lbh' title='Skylight Creative'
          description={[
            { type: 'text', value: 'Application and Resource Development.' },
            { type: 'break' },
            { type: 'text', value: 'Please visit: ' },
            { type: 'link', url: 'https://skylightcreative.com.au', title: 'Skylight Creative', value: 'skylightcreative.com.au' },
          ]}
          itemList={[
            {
              title: 'Skylight Creative website',
              description: 'Designed and developed in WordPress. Hosted as a Ubuntu and Nginx container on Vultr.',
              imageSource: './static/images/portfolio/skylight_intro_thumb-150x150.jpg',
              url: 'https://skylightcreative.com.au',
              target: '_blank',
            },
          ]}
          handleListBlockClick={handleListBlockClick} />
      </Box>
      <Box id='aia' sx={{
        width: '90%',
        maxWidth: '1280px',
        mt: '10px',
        mb: '20px',
        ml: 'auto',
        mr: 'auto',
        padding: '20px',
        pt: '60px',
        pb: '60px',
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderRadius: '6px',
      }}>
        <ListBlock anchor='aia' title='Australian Institute of Architects'
          description={[
            { type: 'text', value: 'New projects produced as the National Web Developer.' },
            { type: 'break' },
            { type: 'text', value: 'Please visit my ' },
            { type: 'link', url: 'https://bitbucket.org/skylightcreative/workspace/projects/WP', title: 'Git', value: 'Git archives' },
            { type: 'text', value: ' to view the source code of these and other applications developed during this period.' },
          ]}
          itemList={[
            {
              title: 'National Awards 2018',
              description: 'A WordPress sub-site developed for 2018 National Conference.',
              imageSource: './static/images/portfolio/aia/naa_18_screendump-150x150.png',
              url: '',
              target: '_blank',
            },
            {
              title: 'Edge: 2018 National Architecture Conference v3',
              description: 'A WordPress sub-site developed for the Australian Institute of Architects annual national conference in 2018. This site was hosted on AIA\'s WordPress server using a variation of the AIA 2017 Theme developed by myself. This is version 3 of the theme which is a minimal reversed black scheme.',
              imageSource: './static/images/portfolio/aia/awards_2018_thumb-150x150.png',
              url: '',
              target: '_blank',
            },
            {
              title: 'National Awards 2017',
              description: 'A WordPress sub-site developed for the Australian Institute of Architects annual national awards event in 2017. This site was hosted on AIA\'s WordPress server using a variation of my AIA 2015 base theme.',
              imageSource: './static/images/portfolio/aia/naa_2017_thumb-150x150.png',
              url: '',
              target: '_blank',
            },
            {
              title: 'PRAXIS: 2017 National Architecture Conference',
              description: 'A WordPress sub-site developed for the Australian Institute of Architects annual conference in 2017. This site was hosted on AIA\'s WordPress server using a variation of my AIA 2015 base theme.',
              imageSource: './static/images/portfolio/aia/animation4_648_720-150x150.gif',
              url: '',
              target: '_blank',
            },
            {
              title: 'Festival of Art & Design 2017',
              description: 'A WordPress sub-site developed for the Adelaide Festival of Art & Design in 2017. This site was hosted on AIA\'s WordPress server using a variation of my AIA 2015 base theme.',
              imageSource: './static/images/portfolio/aia/FAD17-Festival-of-Art-and-Design-thumb-150x150.png',
              url: '',
              target: '_blank',
            },
            {
              title: 'Evoke Regional Conference 2016',
              description: 'A WordPress sub-site developed for the Australian Institute of Architects, Queensland Chapter\'s annual regional conference in 2016. This site was hosted on AIA\'s WordPress server using a variation of my AIA 2015 base theme.',
              imageSource: './static/images/portfolio/aia/evoke_thumb-150x150.png',
              url: '',
              target: '_blank',
            },
            {
              title: 'AIA Intranet',
              description: 'A WordPress sub-site developed for the Australian Institute of Architects intranet. This site was hosted on AIA\'s WordPress intranet server using a variation of my AIA 2015 base theme.',
              imageSource: './static/images/portfolio/aia/intranet_thumb-150x150.png',
              url: '',
              target: '_blank',
            },
            {
              title: 'National Awards 2016',
              description: 'A WordPress sub-site developed for the Australian Institute of Architects annual national awards event in 2016. This site was hosted on AIA\'s WordPress server using a variation of my AIA 2015 base theme.',
              imageSource: './static/images/portfolio/aia/awards_2016_thumb-150x150.png',
              url: '',
              target: '_blank',
            },
            {
              title: 'Vic Awards Archive',
              description: 'A WordPress sub-site developed for the Australian Institute of Architects, Victorian Chapter Awards archive. This site was hosted on AIA\'s WordPress server using a variation of my AIA 2015 base theme.',
              imageSource: './static/images/portfolio/aia/vicawardsarchive_thumb-150x150.png',
              url: '',
              target: '_blank',
            },
            {
              title: 'Where I\'d Like To Live',
              description: 'A WordPress sub-site developed for the Australian Institute of Architects Where I\'d Like To ongoing social media campaign in 2016. This site was hosted on AIA\'s WordPress server using a variation of my AIA 2015 base theme.',
              imageSource: './static/images/portfolio/aia/whereidliketolive_thumb-150x150.jpg',
              url: '',
              target: '_blank',
            },
          ]}
          handleListBlockClick={handleListBlockClick} />
      </Box>
      <Box id='monash' sx={{
        width: '90%',
        maxWidth: '1280px',
        mt: '10px',
        mb: '20px',
        ml: 'auto',
        mr: 'auto',
        padding: '20px',
        pt: '60px',
        pb: '60px',
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderRadius: '6px',
      }}>
        <ListBlock anchor='monash' title='Monash University - Arts Online Presence Team'
          description={[
            { type: 'text', value: 'WordPress projects produced as the Web Designer/Developer in the Faculty of Arts Online Presence Team.' },
            { type: 'break' },
            { type: 'text', value: 'Please visit my ' },
            { type: 'link', url: 'https://bitbucket.org/skylightcreative/workspace/projects/WP', title: 'Git', value: 'Git archives' },
            { type: 'text', value: ' to view the source code of these and other applications developed during this period.' },
            { type: 'break' },
            { type: 'text', value: 'Plugins Developed:' },
            { type: 'break' },
            { type: 'text', value: ' • Arts Breadcrumb - Allows modification of chain of breadcrumbs to current site' },
            { type: 'break' },
            { type: 'text', value: ' • Arts Expertise Short - Displays the Arts Expertise Widget Shortcode' },
            { type: 'break' },
            { type: 'text', value: ' • Arts Footer - Displays the standard Arts footer' },
            { type: 'break' },
            { type: 'text', value: ' • Arts Footer Thirteen - Displays the standard Arts footer' },
            { type: 'break' },
            { type: 'text', value: ' • Arts Keywords Short - Displays the Arts Keywords Widget Shortcode' },
            { type: 'break' },
            { type: 'text', value: ' • Arts Selector Banner - Displays the Arts Selector Widget Shortcode, with no selector and full banner width' },
            { type: 'break' },
            { type: 'text', value: ' • Arts Selector Short - Displays the Arts Selector Widget Shortcode' },
            { type: 'break' },
            { type: 'text', value: ' • Arts Showcase - Displays a showcase gallery' },
            { type: 'break' },
            { type: 'text', value: ' • Arts Site Author Short - Displays the Arts Site Author Widget Shortcode' },
            { type: 'break' },
            { type: 'text', value: ' • Arts StudyAreas Short - Displays the Arts StudyAreas Widget Shortcode' },
            { type: 'break' },
            { type: 'text', value: ' • Arts Topics Short - Displays the Arts Topics Widget Shortcode' },
          ]}
          itemList={[
            {
              title: 'Faculty of Arts Landing Page',
              description: 'Arts Modular Thirteen - child theme for WordPress 2013 Theme.',
              imageSource: './static/images/portfolio/monash/ug_screen_01.png',
              url: '',
              target: '_blank',
            },
            {
              title: 'Master of Tourism Site',
              description: 'Arts Modular Thirteen - child theme for WordPress 2013 Theme.',
              imageSource: './static/images/portfolio/monash/tourism_screen_01.png',
              url: '',
              target: '_blank',
            },
            {
              title: 'Bachelor Arts Site',
              description: 'Arts Modular Thirteen - child theme for WordPress 2013 Theme.',
              imageSource: './static/images/portfolio/monash/ba_screen_01.png',
              url: '',
              target: '_blank',
            },
            {
              title: 'Monash Journalism News (mojo)',
              description: 'Arts Modular Thirteen - child theme for WordPress 2013 Theme.',
              imageSource: './static/images/portfolio/monash/mojo_screen_01.png',
              url: '',
              target: '_blank',
            },
            {
              title: 'School of Music',
              description: 'Arts Modular Thirteen - child theme for WordPress 2013 Theme.',
              imageSource: './static/images/portfolio/monash/music_screen_01.png',
              url: '',
              target: '_blank',
            },
            {
              title: 'Arts Research Showcase',
              description: 'Arts Modular Thirteen - child theme for WordPress 2013 Theme.',
              imageSource: './static/images/portfolio/monash/research_screen_01.png',
              url: '',
              target: '_blank',
            },
            {
              title: 'Arts Profiles Landing Page',
              description: 'Arts Modular Thirteen - child theme for WordPress 2013 Theme.',
              imageSource: './static/images/portfolio/monash/profiles_screen_01.png',
              url: '',
              target: '_blank',
            },
            {
              title: 'Margaret Kartomi Profile Site',
              description: 'Arts Modular Thirteen - child theme for WordPress 2013 Theme.',
              imageSource: './static/images/portfolio/monash/kartomi_screen_01.png',
              url: '',
              target: '_blank',
            },
          ]}
          handleListBlockClick={handleListBlockClick} />
      </Box>
      <Box id='bhi' sx={{
        width: '90%',
        maxWidth: '1280px',
        mt: '10px',
        mb: '20px',
        ml: 'auto',
        mr: 'auto',
        padding: '20px',
        pt: '60px',
        pb: '60px',
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderRadius: '6px',
      }}>
        <Typography variant="h5" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          Box Hill Institute - BeLS
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center', maxWidth: '800px', m: '0 auto 20px' }}>
          Projects produced as the Multimedia Coordinator in the Blended eLearning Solutions group.<br />
          View my BitBucket git repository for the Actionscript code developed for these projects.
        </Typography>
        <Box sx={{ maxWidth: '800px', m: '0 auto' }}>
          <Accordion sx={{ backgroundColor: 'rgba(255,255,255,0.3)' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ color: '#fff' }}>CAMS</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
                <Box>
                  <ThumbBlock id={0} title="" link="" src="./static/images/portfolio/boxhill/cams01.jpg"
                    srcWidth={982} srcHeight={932} boxWidth={235} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={1} title="" link="" src="./static/images/portfolio/boxhill/cams02.jpg"
                    srcWidth={982} srcHeight={725} boxWidth={205} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={2} title="" link="" src="./static/images/portfolio/boxhill/cams03.jpg"
                    srcWidth={982} srcHeight={723} boxWidth={206} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={3} title="" link="" src="./static/images/portfolio/boxhill/cams04.jpg"
                    srcWidth={978} srcHeight={764} boxWidth={194} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={4} title="" link="" src="./static/images/portfolio/boxhill/cams06.jpg"
                    srcWidth={794} srcHeight={396} boxWidth={303} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={5} title="" link="" src="./static/images/portfolio/boxhill/cams07.jpg"
                    srcWidth={796} srcHeight={392} boxWidth={307} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={6} title="" link="" src="./static/images/portfolio/boxhill/cams08.jpg"
                    srcWidth={798} srcHeight={399} boxWidth={307} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={7} title="" link="" src="./static/images/portfolio/boxhill/cams09.jpg"
                    srcWidth={898} srcHeight={447} boxWidth={303} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={8} title="" link="" src="./static/images/portfolio/boxhill/cams10.jpg"
                    srcWidth={980} srcHeight={756} boxWidth={196} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={9} title="" link="" src="./static/images/portfolio/boxhill/cams11.jpg"
                    srcWidth={981} srcHeight={858} boxWidth={174} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={10} title="" link="" src="./static/images/portfolio/boxhill/cams12.jpg"
                    srcWidth={981} srcHeight={735} boxWidth={202} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={11} title="" link="" src="./static/images/portfolio/boxhill/cams13.jpg"
                    srcWidth={977} srcHeight={748} boxWidth={198} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={12} title="" link="" src="./static/images/portfolio/boxhill/cams14.jpg"
                    srcWidth={981} srcHeight={773} boxWidth={192} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={13} title="" link="" src="./static/images/portfolio/boxhill/cams15.jpg"
                    srcWidth={979} srcHeight={773} boxWidth={192} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                </Box>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ backgroundColor: 'rgba(255,255,255,0.3)' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ color: '#fff' }}>Virtual Hospital</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
                <Box>
                  <ThumbBlock id={14} title="" link="" src="./static/images/portfolio/boxhill/vhosp_01.jpg"
                    srcWidth={839} srcHeight={573} boxWidth={222} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={15} title="" link="" src="./static/images/portfolio/boxhill/vhosp_02.jpg"
                    srcWidth={838} srcHeight={573} boxWidth={221} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={16} title="" link="" src="./static/images/portfolio/boxhill/vhosp_03.jpg"
                    srcWidth={842} srcHeight={574} boxWidth={221} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={17} title="" link="" src="./static/images/portfolio/boxhill/vhosp_04.jpg"
                    srcWidth={850} srcHeight={631} boxWidth={204} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={18} title="" link="" src="./static/images/portfolio/boxhill/vhosp_05.jpg"
                    srcWidth={579} srcHeight={440} boxWidth={199} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={19} title="" link="" src="./static/images/portfolio/boxhill/vhosp_06.jpg"
                    srcWidth={578} srcHeight={438} boxWidth={200} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={20} title="" link="" src="./static/images/portfolio/boxhill/vhosp_07.jpg"
                    srcWidth={598} srcHeight={479} boxWidth={189} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={21} title="" link="" src="./static/images/portfolio/boxhill/vhosp_08.jpg"
                    srcWidth={527} srcHeight={562} boxWidth={152} boxHeight={162} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={22} title="" link="" src="./static/images/portfolio/boxhill/vhosp_09.jpg"
                    srcWidth={519} srcHeight={441} boxWidth={179} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={23} title="" link="" src="./static/images/portfolio/boxhill/vhosp_10.jpg"
                    srcWidth={634} srcHeight={326} boxWidth={294} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                </Box>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ backgroundColor: 'rgba(255,255,255,0.3)' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ color: '#fff' }}>DFSS</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
                <Box>
                  <ThumbBlock id={24} title="" link="" src="./static/images/portfolio/boxhill/dfss_interface_01.jpg"
                    srcWidth={1011} srcHeight={598} boxWidth={256} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={25} title="" link="" src="./static/images/portfolio/boxhill/dfss02.jpg"
                    srcWidth={823} srcHeight={570} boxWidth={219} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={26} title="" link="" src="./static/images/portfolio/boxhill/dfss03.jpg"
                    srcWidth={1050} srcHeight={846} boxWidth={188} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={27} title="" link="" src="./static/images/portfolio/boxhill/dfss06.jpg"
                    srcWidth={720} srcHeight={480} boxWidth={227} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={28} title="" link="" src="./static/images/portfolio/boxhill/dfss07.jpg"
                    srcWidth={719} srcHeight={347} boxWidth={313} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={29} title="" link="" src="./static/images/portfolio/boxhill/dfss08.jpg"
                    srcWidth={717} srcHeight={447} boxWidth={243} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={30} title="" link="" src="./static/images/portfolio/boxhill/dfss09.jpg"
                    srcWidth={717} srcHeight={330} boxWidth={328} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={31} title="" link="" src="./static/images/portfolio/boxhill/dfss10.jpg"
                    srcWidth={719} srcHeight={478} boxWidth={228} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                </Box>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ backgroundColor: 'rgba(255,255,255,0.3)' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ color: '#fff' }}>Student Web</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
                <Box>
                  <ThumbBlock id={33} title="" link="" src="./static/images/portfolio/boxhill/gps_concept_01.jpg"
                    srcWidth={1024} srcHeight={605} boxWidth={256} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={34} title="" link="" src="./static/images/portfolio/boxhill/gps_concept_02.jpg"
                    srcWidth={1024} srcHeight={605} boxWidth={256} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={35} title="" link="" src="./static/images/portfolio/boxhill/gps_concept_03.jpg"
                    srcWidth={1024} srcHeight={605} boxWidth={256} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={36} title="" link="" src="./static/images/portfolio/boxhill/mahara_concept_01.jpg"
                    srcWidth={1024} srcHeight={807} boxWidth={192} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={37} title="" link="" src="./static/images/portfolio/boxhill/sw_concept_01.jpg"
                    srcWidth={1024} srcHeight={790} boxWidth={196} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={38} title="" link="" src="./static/images/portfolio/boxhill/sw_concept_03.jpg"
                    srcWidth={1024} srcHeight={790} boxWidth={196} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                </Box>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ backgroundColor: 'rgba(255,255,255,0.3)' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ color: '#fff' }}>Virtual House</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
                <Box>
                  <ThumbBlock id={39} title="" link="" src="./static/images/portfolio/boxhill/bbl_01.jpg"
                    srcWidth={1110} srcHeight={720} boxWidth={233} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={40} title="" link="" src="./static/images/portfolio/boxhill/bbl_02.jpg"
                    srcWidth={1110} srcHeight={720} boxWidth={233} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={41} title="" link="" src="./static/images/portfolio/boxhill/bbl_03.jpg"
                    srcWidth={1022} srcHeight={720} boxWidth={233} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={42} title="" link="" src="./static/images/portfolio/boxhill/bbl_04.jpg"
                    srcWidth={1022} srcHeight={720} boxWidth={233} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={43} title="" link="" src="./static/images/portfolio/boxhill/bbl_05.jpg"
                    srcWidth={1022} srcHeight={720} boxWidth={233} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={44} title="" link="" src="./static/images/portfolio/boxhill/vhouse_interface_01.jpg"
                    srcWidth={892} srcHeight={601} boxWidth={225} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                </Box>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ backgroundColor: 'rgba(255,255,255,0.3)' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ color: '#fff' }}>Generic Competency Units</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
                <Box>
                  <ThumbBlock id={45} title="" link="" src="./static/images/portfolio/boxhill/gcu01.jpg"
                    srcWidth={935} srcHeight={644} boxWidth={220} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={46} title="" link="" src="./static/images/portfolio/boxhill/gcu02.jpg"
                    srcWidth={980} srcHeight={740} boxWidth={201} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={47} title="" link="" src="./static/images/portfolio/boxhill/gcu03.jpg"
                    srcWidth={977} srcHeight={734} boxWidth={202} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={48} title="" link="" src="./static/images/portfolio/boxhill/gcu04.jpg"
                    srcWidth={975} srcHeight={734} boxWidth={201} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={49} title="" link="" src="./static/images/portfolio/boxhill/gcu05.jpg"
                    srcWidth={980} srcHeight={786} boxWidth={189} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={50} title="" link="" src="./static/images/portfolio/boxhill/gcu06.jpg"
                    srcWidth={975} srcHeight={795} boxWidth={186} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={51} title="" link="" src="./static/images/portfolio/boxhill/gcu08.jpg"
                    srcWidth={982} srcHeight={798} boxWidth={187} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={52} title="" link="" src="./static/images/portfolio/boxhill/gcu09.jpg"
                    srcWidth={979} srcHeight={795} boxWidth={187} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={53} title="" link="" src="./static/images/portfolio/boxhill/gcu10.jpg"
                    srcWidth={1066} srcHeight={647} boxWidth={249} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={54} title="" link="" src="./static/images/portfolio/boxhill/gcu11.jpg"
                    srcWidth={1052} srcHeight={661} boxWidth={241} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={55} title="" link="" src="./static/images/portfolio/boxhill/gcu12.jpg"
                    srcWidth={976} srcHeight={751} boxWidth={197} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={56} title="" link="" src="./static/images/portfolio/boxhill/gcu13.jpg"
                    srcWidth={978} srcHeight={786} boxWidth={189} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={57} title="" link="" src="./static/images/portfolio/boxhill/gcu14.jpg"
                    srcWidth={972} srcHeight={793} boxWidth={189} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={58} title="" link="" src="./static/images/portfolio/boxhill/gcu15.jpg"
                    srcWidth={980} srcHeight={778} boxWidth={191} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={59} title="" link="" src="./static/images/portfolio/boxhill/gcu16.jpg"
                    srcWidth={982} srcHeight={723} boxWidth={206} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={60} title="" link="" src="./static/images/portfolio/boxhill/gcu17.jpg"
                    srcWidth={982} srcHeight={722} boxWidth={206} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                </Box>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ backgroundColor: 'rgba(255,255,255,0.3)' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ color: '#fff' }}>Diploma of Management</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
                <Box>
                  <ThumbBlock id={61} title="" link="" src="./static/images/portfolio/boxhill/dipman_01.jpg"
                    srcWidth={653} srcHeight={480} boxWidth={206} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={62} title="" link="" src="./static/images/portfolio/boxhill/dipman2.jpg"
                    srcWidth={653} srcHeight={480} boxWidth={206} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={63} title="" link="" src="./static/images/portfolio/boxhill/dipman3.jpg"
                    srcWidth={653} srcHeight={480} boxWidth={206} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={64} title="" link="" src="./static/images/portfolio/boxhill/dipman4.jpg"
                    srcWidth={653} srcHeight={480} boxWidth={206} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={65} title="" link="" src="./static/images/portfolio/boxhill/dipman5.jpg"
                    srcWidth={653} srcHeight={480} boxWidth={206} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={66} title="" link="" src="./static/images/portfolio/boxhill/dipman6.jpg"
                    srcWidth={653} srcHeight={480} boxWidth={206} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={67} title="" link="" src="./static/images/portfolio/boxhill/dipman7.jpg"
                    srcWidth={653} srcHeight={480} boxWidth={206} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={68} title="" link="" src="./static/images/portfolio/boxhill/dipman8.jpg"
                    srcWidth={653} srcHeight={480} boxWidth={206} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                </Box>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ backgroundColor: 'rgba(255,255,255,0.3)' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ color: '#fff' }}>Cert IV in TAA</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
                <Box>
                  <ThumbBlock id={69} title="" link="" src="./static/images/portfolio/boxhill/taa_01.jpg"
                    srcWidth={897} srcHeight={620} boxWidth={219} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={70} title="" link="" src="./static/images/portfolio/boxhill/taa2.jpg"
                    srcWidth={720} srcHeight={480} boxWidth={227} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={71} title="" link="" src="./static/images/portfolio/boxhill/taa3.jpg"
                    srcWidth={720} srcHeight={480} boxWidth={227} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={72} title="" link="" src="./static/images/portfolio/boxhill/taa4.jpg"
                    srcWidth={720} srcHeight={480} boxWidth={227} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={73} title="" link="" src="./static/images/portfolio/boxhill/taa5.jpg"
                    srcWidth={720} srcHeight={480} boxWidth={227} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={74} title="" link="" src="./static/images/portfolio/boxhill/taa6.jpg"
                    srcWidth={720} srcHeight={480} boxWidth={227} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={75} title="" link="" src="./static/images/portfolio/boxhill/taa7.jpg"
                    srcWidth={720} srcHeight={480} boxWidth={227} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={76} title="" link="" src="./static/images/portfolio/boxhill/taa8.jpg"
                    srcWidth={720} srcHeight={480} boxWidth={227} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={77} title="" link="" src="./static/images/portfolio/boxhill/taa9.jpg"
                    srcWidth={720} srcHeight={480} boxWidth={227} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                </Box>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ backgroundColor: 'rgba(255,255,255,0.3)' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ color: '#fff' }}>Sustainable Office Block</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
                <Box>
                  <ThumbBlock id={69} title="" link="" src="./static/images/portfolio/boxhill/so_02.jpg"
                    srcWidth={973} srcHeight={734} boxWidth={231} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={70} title="" link="" src="./static/images/portfolio/boxhill/so_04.jpg"
                    srcWidth={973} srcHeight={734} boxWidth={201} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={71} title="" link="" src="./static/images/portfolio/boxhill/so_06.jpg"
                    srcWidth={979} srcHeight={756} boxWidth={196} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={72} title="" link="" src="./static/images/portfolio/boxhill/so_09.jpg"
                    srcWidth={978} srcHeight={773} boxWidth={192} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={73} title="" link="" src="./static/images/portfolio/boxhill/so_11.jpg"
                    srcWidth={978} srcHeight={755} boxWidth={196} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={74} title="" link="" src="./static/images/portfolio/boxhill/so_22.jpg"
                    srcWidth={800} srcHeight={498} boxWidth={243} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                  <ThumbBlock id={75} title="" link="" src="./static/images/portfolio/boxhill/so_interface_01.jpg"
                    srcWidth={969} srcHeight={571} boxWidth={257} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
                </Box>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box sx={{ mt: '40px', mb: '20px' }} >
          <Link color="inherit" title="Back to the top" onClick={() => {handleScroll('');}}>
            <img src="./static/images/portfolio/up_arrow_gold.png" loading="lazy" />
          </Link>
        </Box>
      </Box>
      <Box id='uca' sx={{
        width: '90%',
        maxWidth: '1280px',
        mt: '10px',
        mb: '20px',
        ml: 'auto',
        mr: 'auto',
        padding: '20px',
        pt: '60px',
        pb: '60px',
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderRadius: '6px',
      }}>
        <Typography variant="h5" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          Uniting Church in Australia - YACMU
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          Projects produced as the Senior Designer/Developer in the Youth & Children's Ministry Unit.<br />(coming soon)
        </Typography>
        <Box sx={{ mt: '40px', mb: '20px' }} >
          <Link color="inherit" title="Back to the top" onClick={() => {handleScroll('');}}>
            <img src="./static/images/portfolio/up_arrow_gold.png" loading="lazy" />
          </Link>
        </Box>
      </Box>
      <Box id='misc' sx={{
        width: '90%',
        maxWidth: '1280px',
        mt: '10px',
        mb: '20px',
        ml: 'auto',
        mr: 'auto',
        padding: '20px',
        pt: '60px',
        pb: '60px',
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderRadius: '6px',
      }}>
        <Typography variant="h5" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          Misc Web Projects
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          Miscellaneous sites and applications designed or developed as contract work.<br />(coming soon)
        </Typography>
        <Box sx={{ mt: '40px', mb: '20px' }} >
          <Link color="inherit" title="Back to the top" onClick={() => {handleScroll('');}}>
            <img src="./static/images/portfolio/up_arrow_gold.png" loading="lazy" />
          </Link>
        </Box>
      </Box>
    </>
  )
};

export default Websites;