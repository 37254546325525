import React, { FC, ReactElement, ReactNode } from 'react';
import { useState } from 'react';

import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import EmailIcon from '@mui/icons-material/Email';
import Icon from '@mui/material/Icon';

import Typography from '@mui/material/Typography';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme();

const testHash = '2w3q4r2345rw4tfewrwpfgsdgdfg';
console.log('testHash=' + testHash);

export interface AboutProps {
  anchor: string,
  handlePageClick: Function,
}

const handleScroll = (itemID: string) => {
  console.log('handleScroll() called with ' + itemID);
  if (itemID && itemID !== '') {
    const element = document.getElementById(itemID);
    element?.scrollIntoView({ behavior: 'smooth' });
  } else {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }
};

const About: FC<AboutProps> = ({ anchor, handlePageClick }): ReactElement => {

  return (
    <>
      <Box sx={{
        width: '90%',
        maxWidth: '1280px',
        mt: '10px',
        mb: '20px',
        ml: 'auto',
        mr: 'auto',
        padding: '20px',
        pt: '60px',
        pb: '60px',
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderRadius: '6px',
      }}>
        <Typography variant="h4" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          About Tony Gillan
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center', maxWidth: '800px', m: '0 auto' }}>
          Tony Gillan is an experienced web developer and creative specialist with expertise in building fast, scalable, and secure web applications using React.js, Next.js, and Node.js. He also has extensive experience developing websites on WordPress and creating engaging digital experiences through resource development, multimedia production, and graphic design. He is skilled in both front-end and back-end single page web app development with secure server hosting and ecommerce systems.
        </Typography>
      </Box>
      <Box id='contact' sx={{
        width: '90%',
        maxWidth: '1280px',
        mt: '10px',
        mb: '20px',
        ml: 'auto',
        mr: 'auto',
        padding: '20px',
        pt: '60px',
        pb: '60px',
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderRadius: '6px',
      }}>
        <Typography variant="h5" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          Contact
        </Typography>
        <Box sx={{ pt: '30px' }}>
          <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
            <Link color="inherit" href="mailto:info@tonygillan.com" title="Email">
              <EmailIcon />
            </Link>
            <Box>info@tonygillan.com</Box>
            <br />
            <Link color="inherit" href="https://www.linkedin.com/in/tgillan/" target="_blank" title="LinkedIn">
              <img src="./static/images/portfolio/linkedin.png" width="30px" height="30px" loading="lazy" />
            </Link>
            <Box>LinkedIn portfolio</Box>
            <br />
            <Link color="inherit" href="https://bitbucket.org/skylightcreative/workspace/repositories" target="_blank" title="Bitbucket">
              <img src="./static/images/portfolio/bitbucket_logo_30x30.png" width="30px" height="30px" loading="lazy" />
            </Link>
            <Box>Bitbucket Git repositories</Box>
          </Typography>
        </Box>
        <Box sx={{ mt: '40px', mb: '20px' }} >
          <Link color="inherit" title="Back to the top" onClick={() => { handleScroll(''); }}>
            <img src="./static/images/portfolio/up_arrow_gold.png" loading="lazy" />
          </Link>
        </Box>
      </Box>
      <Box id='skillset' sx={{
        width: '90%',
        maxWidth: '1280px',
        mt: '10px',
        mb: '20px',
        ml: 'auto',
        mr: 'auto',
        padding: '20px',
        pt: '60px',
        pb: '60px',
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderRadius: '6px',
      }}>
        <Typography variant="h5" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          Skillset
        </Typography>
        <Box sx={{ ml: 'auto', mr: 'auto', maxWidth: '600px', width: '100%' }} >
          <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'left' }}>
            Core skills and experience include:<br />
            <ul>
              <li>HTML5, CSS3 &amp; JavaScript/TypeScript</li>
              <li>React.js, Next,js, MaterialUI, jQuery &amp; Bootstrap</li>
              <li>Node.js server, Express, Socket.IO</li>
              <li>.NET application development (.NET 7 API with SignalR, C#, React/TypeScript)</li>
              <li>SQL Server &amp; MongoDB</li>
              <li>LAMP web service stack - Ubuntu, RedHat, vultr, Rackspace</li>
              <li>AWS (EC2, Lambda, API, S3, Kubernetes)</li>
              <li>Magento international retail site development and maintenance with eCommerce and analytics integration</li>
              <li>WordPress multisite setup, theme, plugin, and eCommerce dev using PHP &amp; WooCommerce</li>
              <li>Software development workflows using Git, Sass, Webpack, Jenkins &amp; Jest/Jasmine TDD</li>
              <li>Agile project management (SCRUM, Trello, Slack, Kanban, Jira, Microsoft TFS)</li>
              <li>Educational LMS design &amp; dev using Moodle</li>
              <li>3D resource and world development using 3ds Max, Unreal Engine &amp; OpenSimulator</li>
            </ul>
          </Typography>
        </Box>
        <Box sx={{ mt: '40px', mb: '20px' }} >
          <Link color="inherit" title="Back to the top" onClick={() => { handleScroll(''); }}>
            <img src="./static/images/portfolio/up_arrow_gold.png" loading="lazy" />
          </Link>
        </Box>
      </Box>
    </>
  )
};

export default About;