import React, { FC, ReactElement, ReactNode } from 'react';
import { useState } from 'react';

import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

import Typography from '@mui/material/Typography';

import ReactPlayer from 'react-player/lazy';

import Carousel from './Carousel';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme();

export interface AnimationsProps {
  anchor: string;
  handlePageClick: Function;
}

const handleScroll = (itemID: string) => {
  console.log('handleScroll() called with ' + itemID);
  if (itemID && itemID !== '') {
    const element = document.getElementById(itemID);
    element?.scrollIntoView({ behavior: 'smooth' });
  } else {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }
};

const Animations: FC<AnimationsProps> = ({ anchor, handlePageClick }): ReactElement => {

  return (
    <>
      <Box sx={{
        width: '90%',
        maxWidth: '1280px',
        mt: '10px',
        mb: '20px',
        ml: 'auto',
        mr: 'auto',
        padding: '20px',
        pt: '60px',
        pb: '60px',
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderRadius: '6px',
      }}>
        <Typography variant="h4" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          Animations &amp; Virtual Worlds
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          Resources developed in Unreal Engine, 3DSMax, Flash, and virtual world environments.
        </Typography>
        <Box sx={{ pt: '30px' }}>
          <Link color="inherit" title="Unreal Engine" onClick={() => {handleScroll('unreal');}}>
            <img src="./static/images/portfolio/unreal-engine-4-logo-150x150.png" width="50px" height="50px" loading="lazy" />
          </Link>
          &nbsp;
          <Link color="inherit" title="3DS Max" onClick={() => {handleScroll('3dsmax');}}>
            <img src="./static/images/portfolio/3ds-max-logo-150x150.png" width="50px" height="50px" loading="lazy" />
          </Link>
          &nbsp;
          <Link color="inherit" title="Minecraft" onClick={() => {handleScroll('minecraft');}}>
            <img src="./static/images/portfolio/minecraft-logo-150x150.png" width="50px" height="50px" loading="lazy" />
          </Link>
          &nbsp;
          <Link color="inherit" title="Cloud Party" onClick={() => {handleScroll('cloudparty');}}>
            <img src="./static/images/portfolio/cloud-logo-150x150.png" width="50px" height="50px" loading="lazy" />
          </Link>
          &nbsp;
          <Link color="inherit" title="Animated Clips" onClick={() => {handleScroll('animations');}}>
            <img src="./static/images/portfolio/adobe-flash-8-logo-150x150.png" width="50px" height="50px" loading="lazy" />
          </Link>
        </Box>
      </Box>
      <Box id='unreal' sx={{
        width: '90%',
        maxWidth: '1280px',
        mt: '10px',
        mb: '20px',
        ml: 'auto',
        mr: 'auto',
        padding: '20px',
        pt: '60px',
        pb: '60px',
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderRadius: '6px',
      }}>
        <Typography variant="h5" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          Art Gallery
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          <img src="./static/images/portfolio/unreal-engine-4-logo-150x150.png" width="50px" height="50px" loading="lazy" /><br />
          (developed in Unreal Engine 4.7)<br />
          Multiple gallery spaces and outdoor ampitheatre.
        </Typography>
        <Box sx={{ mt: '40px', mb: '20px', mr: 'auto', ml: 'auto', width: '100%', maxWidth: '960px' }} >
          <img src="./static/images/portfolio/art-gallery-26-01-960.jpg" width="100%" loading="lazy" />
        </Box>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          <a href="https://tonygillan.com/#" title="Art Gallery">Art Gallery</a> (coming soon)
        </Typography>
        <Box sx={{ mt: '40px', mb: '20px' }} >
          <Link color="inherit" title="Back to the top" onClick={() => {handleScroll('');}}>
            <img src="./static/images/portfolio/up_arrow_gold.png" loading="lazy" />
          </Link>
        </Box>
      </Box>
      <Box id='3dsmax' sx={{
        width: '90%',
        maxWidth: '1280px',
        mt: '10px',
        mb: '20px',
        ml: 'auto',
        mr: 'auto',
        padding: '20px',
        pt: '60px',
        pb: '60px',
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderRadius: '6px',
      }}>
        <Typography variant="h5" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          St Columb's Church
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center', maxWidth: '800px', m: '0 auto' }}>
          <img src="./static/images/portfolio/3ds-max-logo-150x150.png" width="50px" height="50px" loading="lazy" /><br />
          (developed in 3DS Max)<br />
          A fly-through the interior space of St. Columb's Church in Hawthorn. This interior was developed as an aid for discussion about proposed major changes to the seating space.
        </Typography>
        <Box sx={{ mt: '40px', mb: '20px', mr: 'auto', ml: 'auto', width: '100%', maxWidth: '640px' }} >
          <ReactPlayer url="https://vimeo.com/78303961" width="100%" config={{
            youtube: {
              playerVars: { showinfo: 1 }
            },
            vimeo: {
              playerOptions: { controls: true }
            }
          }} />
        </Box>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          <a href="https://vimeo.com/78303961" title="St Columbs Church">St Columb's Church</a> (Vimeo)
        </Typography>
        <Box sx={{ mt: '40px', mb: '20px' }} >
          <Link color="inherit" title="Back to the top" onClick={() => {handleScroll('');}}>
            <img src="./static/images/portfolio/up_arrow_gold.png" loading="lazy" />
          </Link>
        </Box>
      </Box>
      <Box id='minecraft' sx={{
        width: '90%',
        maxWidth: '1280px',
        mt: '10px',
        mb: '20px',
        ml: 'auto',
        mr: 'auto',
        padding: '20px',
        pt: '60px',
        pb: '60px',
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderRadius: '6px',
      }}>
        <Typography variant="h5" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          Minecraft Virtual Worlds
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center', maxWidth: '800px', m: '0 auto' }}>
          <img src="./static/images/portfolio/minecraft-logo-150x150.png" width="50px" height="50px" loading="lazy" /><br />
          (developed in Minecraft Bedrock)<br />
          Minecraft virtual world levels with multiple castles, underground mine, complexes and redstone interactivities linked by minecart rail systems.
        </Typography>


        <Typography variant="h5" gutterBottom sx={{ color: '#fff', textAlign: 'center', mt: '10px' }}>
          Origin World
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          description
        </Typography>
        <Box sx={{ mt: '40px', mb: '20px', mr: 'auto', ml: 'auto', width: '100%', maxWidth: '640px' }} >
          <ReactPlayer url="https://www.youtube.com/watch?v=ZUOTDdawb-U" width="100%" config={{
            youtube: {
              playerVars: { showinfo: 1 }
            },
            vimeo: {
              playerOptions: { controls: true }
            }
          }} />
        </Box>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          <a href="https://www.youtube.com/watch?v=ZUOTDdawb-U" title="Origin World">Origin World</a> (YouTube unedited)
        </Typography>
        <Box sx={{ mt: '40px', mb: '20px' }} >
          <Link color="inherit" title="Back to the top" onClick={() => {handleScroll('');}}>
            <img src="./static/images/portfolio/up_arrow_gold.png" loading="lazy" />
          </Link>
        </Box>
        <hr style={{ borderColor: "#2a3721", borderStyle: "dashed" }} />


        <Typography variant="h5" gutterBottom sx={{ color: '#fff', textAlign: 'center', mt: '10px' }}>
          River World - Part A
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          The Remote Villages
        </Typography>
        <Box sx={{ mt: '40px', mb: '20px', mr: 'auto', ml: 'auto', width: '100%', maxWidth: '640px' }} >
          <ReactPlayer url="https://www.youtube.com/watch?v=857TXV_DNeY" width="100%" config={{
            youtube: {
              playerVars: { showinfo: 1 }
            },
            vimeo: {
              playerOptions: { controls: true }
            }
          }} />
        </Box>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          <a href="https://www.youtube.com/watch?v=857TXV_DNeY" title="River World - Part A">River World - Part A</a> (YouTube unedited)
        </Typography>
        <Box sx={{ mt: '40px', mb: '20px' }} >
          <Link color="inherit" title="Back to the top" onClick={() => {handleScroll('');}}>
            <img src="./static/images/portfolio/up_arrow_gold.png" loading="lazy" />
          </Link>
        </Box>
        <hr style={{ borderColor: "#2a3721", borderStyle: "dashed" }} />


        <Typography variant="h5" gutterBottom sx={{ color: '#fff', textAlign: 'center', mt: '10px' }}>
          River World - Part B
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          The Coastal Towers
        </Typography>
        <Box sx={{ mt: '40px', mb: '20px', mr: 'auto', ml: 'auto', width: '100%', maxWidth: '640px' }} >
          <ReactPlayer url="https://www.youtube.com/watch?v=ROK4kStrz0Y" width="100%" config={{
            youtube: {
              playerVars: { showinfo: 1 }
            },
            vimeo: {
              playerOptions: { controls: true }
            }
          }} />
        </Box>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          <a href="https://www.youtube.com/watch?v=ROK4kStrz0Y" title="River World - Part B">River World - Part B</a> (YouTube unedited)
        </Typography>
        <Box sx={{ mt: '40px', mb: '20px' }} >
          <Link color="inherit" title="Back to the top" onClick={() => {handleScroll('');}}>
            <img src="./static/images/portfolio/up_arrow_gold.png" loading="lazy" />
          </Link>
        </Box>
        <hr style={{ borderColor: "#2a3721", borderStyle: "dashed" }} />


        <Typography variant="h5" gutterBottom sx={{ color: '#fff', textAlign: 'center', mt: '10px' }}>
          River World - Part C
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          The Original Rift
        </Typography>
        <Box sx={{ mt: '40px', mb: '20px', mr: 'auto', ml: 'auto', width: '100%', maxWidth: '640px' }} >
          <ReactPlayer url="https://www.youtube.com/watch?v=-11Qsb7ogso" width="100%" config={{
            youtube: {
              playerVars: { showinfo: 1 }
            },
            vimeo: {
              playerOptions: { controls: true }
            }
          }} />
        </Box>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          <a href="https://www.youtube.com/watch?v=-11Qsb7ogso" title="River World - Part C">River World - Part C</a> (YouTube unedited)
        </Typography>
        <Box sx={{ mt: '40px', mb: '20px' }} >
          <Link color="inherit" title="Back to the top" onClick={() => {handleScroll('');}}>
            <img src="./static/images/portfolio/up_arrow_gold.png" loading="lazy" />
          </Link>
        </Box>
        <hr style={{ borderColor: "#2a3721", borderStyle: "dashed" }} />


        <Typography variant="h5" gutterBottom sx={{ color: '#fff', textAlign: 'center', mt: '10px' }}>
          River World - Part D
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          Treehouse + Fortresses
        </Typography>
        <Box sx={{ mt: '40px', mb: '20px', mr: 'auto', ml: 'auto', width: '100%', maxWidth: '640px' }} >
          <ReactPlayer url="https://www.youtube.com/watch?v=rM1UYggD-YY" width="100%" config={{
            youtube: {
              playerVars: { showinfo: 1 }
            },
            vimeo: {
              playerOptions: { controls: true }
            }
          }} />
        </Box>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          <a href="https://www.youtube.com/watch?v=rM1UYggD-YY" title="River World - Part D">River World - Part D</a> (YouTube unedited)
        </Typography>
        <Box sx={{ mt: '40px', mb: '20px' }} >
          <Link color="inherit" title="Back to the top" onClick={() => {handleScroll('');}}>
            <img src="./static/images/portfolio/up_arrow_gold.png" loading="lazy" />
          </Link>
        </Box>
        <hr style={{ borderColor: "#2a3721", borderStyle: "dashed" }} />


        <Typography variant="h5" gutterBottom sx={{ color: '#fff', textAlign: 'center', mt: '10px' }}>
          River World - Part E
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          The New World
        </Typography>
        <Box sx={{ mt: '40px', mb: '20px', mr: 'auto', ml: 'auto', width: '100%', maxWidth: '640px' }} >
          <ReactPlayer url="https://www.youtube.com/watch?v=R2sOSnVTq4Y" width="100%" config={{
            youtube: {
              playerVars: { showinfo: 1 }
            },
            vimeo: {
              playerOptions: { controls: true }
            }
          }} />
        </Box>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          <a href="https://www.youtube.com/watch?v=R2sOSnVTq4Y" title="River World - Part E">River World - Part E</a> (YouTube unedited)
        </Typography>
        <Box sx={{ mt: '40px', mb: '20px' }} >
          <Link color="inherit" title="Back to the top" onClick={() => {handleScroll('');}}>
            <img src="./static/images/portfolio/up_arrow_gold.png" loading="lazy" />
          </Link>
        </Box>
      </Box>
      <Box id='cloudparty' sx={{
        width: '90%',
        maxWidth: '1280px',
        mt: '10px',
        mb: '20px',
        ml: 'auto',
        mr: 'auto',
        padding: '20px',
        pt: '60px',
        pb: '60px',
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderRadius: '6px',
      }}>
        <Typography variant="h5" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          Virtual World Builds
        </Typography>
        <Typography variant="h5" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          Skydeck Alpha
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          <img src="./static/images/portfolio/cloud-logo-150x150.png" width="50px" height="50px" loading="lazy" /><br />
          (developed in Cloud Party)<br />
          An enclosed courtyard garden on top of a space station with docks and internal living space.
        </Typography>
        <Box sx={{ mt: '40px', mb: '20px', mr: 'auto', ml: 'auto', width: '100%', maxWidth: '640px' }} >
          <ReactPlayer url="https://vimeo.com/81348277" width="100%" config={{
            youtube: {
              playerVars: { showinfo: 1 }
            },
            vimeo: {
              playerOptions: { controls: true }
            }
          }} />
        </Box>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          <a href="https://vimeo.com/81348277" title="Skydeck Alpha">Skydeck Alpha</a> (Vimeo)
        </Typography>
        <Box sx={{ mt: '40px', mb: '20px' }} >
          <Link color="inherit" title="Back to the top" onClick={() => {handleScroll('');}}>
            <img src="./static/images/portfolio/up_arrow_gold.png" loading="lazy" />
          </Link>
        </Box>
        <hr style={{ borderColor: "#2a3721", borderStyle: "dashed" }} />
        <Typography variant="h5" gutterBottom sx={{ color: '#fff', textAlign: 'center', mt: '10px' }}>
          Hyperion Gallery
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          (developed in Cloud Party)
          An art gallery complex with studios, gallery space, and gardens.
        </Typography>
        <Box sx={{ mt: '40px', mb: '20px', mr: 'auto', ml: 'auto', width: '100%', maxWidth: '640px' }} >
          <ReactPlayer url="https://vimeo.com/81348276" width="100%" config={{
            youtube: {
              playerVars: { showinfo: 1 }
            },
            vimeo: {
              playerOptions: { controls: true }
            }
          }} />
        </Box>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          <a href="https://vimeo.com/81348276" title="Hyperion Gallery">Hyperion Gallery</a> (Vimeo)
        </Typography>
        <Box sx={{ mt: '40px', mb: '20px' }} >
          <Link color="inherit" title="Back to the top" onClick={() => {handleScroll('');}}>
            <img src="./static/images/portfolio/up_arrow_gold.png" loading="lazy" />
          </Link>
        </Box>
        <hr style={{ borderColor: "#2a3721", borderStyle: "dashed" }} />
        <Typography variant="h5" gutterBottom sx={{ color: '#fff', textAlign: 'center', mt: '10px' }}>
          Hyperion Marooned
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          (developed in Cloud Party)<br />
          An open island space. Includes several structures & the wreck of the starship "Hyperion" to explore.
        </Typography>
        <Box sx={{ mt: '40px', mb: '20px', mr: 'auto', ml: 'auto', width: '100%', maxWidth: '640px' }} >
          <ReactPlayer url="https://vimeo.com/80230114" width="100%" config={{
            youtube: {
              playerVars: { showinfo: 1 }
            },
            vimeo: {
              playerOptions: { controls: true }
            }
          }} />
        </Box>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          <a href="https://vimeo.com/80230114" title="Hyperion Marooned">Hyperion Marooned</a> (Vimeo)
        </Typography>
        <Box sx={{ mt: '40px', mb: '20px' }} >
          <Link color="inherit" title="Back to the top" onClick={() => {handleScroll('');}}>
            <img src="./static/images/portfolio/up_arrow_gold.png" loading="lazy" />
          </Link>
        </Box>
        <hr style={{ borderColor: "#2a3721", borderStyle: "dashed" }} />

        <Typography variant="h5" gutterBottom sx={{ color: '#fff', textAlign: 'center', mt: '10px' }}>
          The Citadel
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          A development structure exploring internal and external space and movement between rooms.
        </Typography>
        <Box sx={{ mt: '10px', ml: 'auto', mr: 'auto', maxWidth: '640px' }} >
          <Carousel
            anchor={{}}
            handlePageClick={() => { console.log('carousel clicked'); }}
            imageList={[
              { label: 'The Citadel 01', imgPath: './static/images/portfolio/cloudparty/thumb/AjMfg2jwN-640x512.jpg', },
              { label: 'The Citadel 02', imgPath: './static/images/portfolio/cloudparty/thumb/Az3lvjefw-640x512.jpg', },
              { label: 'The Citadel 12', imgPath: './static/images/portfolio/cloudparty/thumb/A5tga7Bme-640x512.jpg', },
              { label: 'The Citadel 10', imgPath: './static/images/portfolio/cloudparty/thumb/AfXF67xly-640x512.jpg', },
              { label: 'The Citadel 11', imgPath: './static/images/portfolio/cloudparty/thumb/A0ZFo94tW-640x512.jpg', },
              { label: 'The Citadel 08', imgPath: './static/images/portfolio/cloudparty/thumb/AKpu4LZr3-640x512.jpg', },
              { label: 'The Citadel 09', imgPath: './static/images/portfolio/cloudparty/thumb/AJcmYF4av-640x512.jpg', },
              { label: 'The Citadel 06', imgPath: './static/images/portfolio/cloudparty/thumb/AEKBMQmiZ-640x512.jpg', },
              { label: 'The Citadel 07', imgPath: './static/images/portfolio/cloudparty/thumb/AgpJYvz7N-640x512.jpg', },
              { label: 'The Citadel 05', imgPath: './static/images/portfolio/cloudparty/thumb/AIz80lsa4-640x512.jpg', },
              { label: 'The Citadel 03', imgPath: './static/images/portfolio/cloudparty/thumb/AJXIUxSGs-640x512.jpg', },
              { label: 'The Citadel 04', imgPath: './static/images/portfolio/cloudparty/thumb/Ab0UPsgAb-640x512.jpg', },
            ]} />
        </Box>
        <Box sx={{ mt: '40px', mb: '20px' }} >
          <Link color="inherit" title="Back to the top" onClick={() => {handleScroll('');}}>
            <img src="./static/images/portfolio/up_arrow_gold.png" loading="lazy" />
          </Link>
        </Box>
        <hr style={{ borderColor: "#2a3721", borderStyle: "dashed" }} />

        <Typography variant="h5" gutterBottom sx={{ color: '#fff', textAlign: 'center', mt: '10px' }}>
          Plaza
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          A plaza area with gardens and forum (developed in Cloud Party)
        </Typography>
        <Box sx={{ mt: '10px', ml: 'auto', mr: 'auto', maxWidth: '640px' }} >
          <Carousel
            anchor={{}}
            handlePageClick={() => { console.log('carousel clicked'); }}
            imageList={[
              { label: 'The Plaza 01', imgPath: './static/images/portfolio/cloudparty/thumb/A2q3CF2IL-640x512.jpg', },
              { label: 'The Plaza 02', imgPath: './static/images/portfolio/cloudparty/thumb/A7rKQSR5F-640x512.jpg', },
              { label: 'The Plaza 03', imgPath: './static/images/portfolio/cloudparty/thumb/ARxlOXM0c-640x512.jpg', },
              { label: 'The Plaza 04', imgPath: './static/images/portfolio/cloudparty/thumb/AWxnCcR4L-640x512.jpg', },
              { label: 'The Plaza 05', imgPath: './static/images/portfolio/cloudparty/thumb/AZ3xO1cu8-640x512.jpg', },
            ]} />
        </Box>
        <Box sx={{ mt: '40px', mb: '20px' }} >
          <Link color="inherit" title="Back to the top" onClick={() => {handleScroll('');}}>
            <img src="./static/images/portfolio/up_arrow_gold.png" loading="lazy" />
          </Link>
        </Box>
      </Box>
      <Box id='animations' sx={{
        width: '90%',
        maxWidth: '1280px',
        mt: '10px',
        mb: '20px',
        ml: 'auto',
        mr: 'auto',
        padding: '20px',
        pt: '60px',
        pb: '60px',
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderRadius: '6px',
      }}>
        <Typography variant="h5" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          Animated Resources
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          <img src="./static/images/portfolio/adobe-flash-8-logo-150x150.png" width="50px" height="50px" loading="lazy" /><br />
          (developed in Adobe Flash)<br />
          These short animated video clips were produced from 2001-2006 for the Enhance Multimedia Project supported by the Uniting Church in Australia, Queensland.
        </Typography>
        <Box sx={{ mt: '40px', mb: '20px', mr: 'auto', ml: 'auto', width: '100%', maxWidth: '640px' }} >
          <ReactPlayer url="https://vimeo.com/showcase/2591059/video/78306361" width="100%" config={{
            youtube: {
              playerVars: { showinfo: 1 }
            },
            vimeo: {
              playerOptions: { controls: true }
            }
          }} />
        </Box>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          <a href="https://vimeo.com/showcase/2591059" title="Resources for Reflection">Resources for Reflection</a> (Vimeo Showcase)
        </Typography>
        <Box sx={{ mt: '40px', mb: '20px' }} >
          <Link color="inherit" title="Back to the top" onClick={() => {handleScroll('');}}>
            <img src="./static/images/portfolio/up_arrow_gold.png" loading="lazy" />
          </Link>
        </Box>
      </Box>
    </>
  )
};

export default Animations;