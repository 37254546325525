import React, { FC, ReactElement, ReactNode } from 'react';
import { useState } from 'react';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Copyright from '../footer/Copyright';

import WebApps from '../pages/WebApps';
import Websites from '../pages/Websites';
import Animations from '../pages/Animations';
import GraphicDesign from '../pages/GraphicDesign';
import About from '../pages/About';

const theme = createTheme();

export interface MainViewProps {
  pageID: string,
  handlePageEvent: Function,
}

const MainView: FC<MainViewProps> = ({ pageID, handlePageEvent }): ReactElement => {

  const getPage = (id: string) => {
    switch (id) {
      case 'web-apps':
        return (<WebApps anchor={''} handlePageClick={handlePageClick} />);
        break;
      case 'websites':
        return (<Websites anchor={''} handlePageClick={handlePageClick} />);
        break;
      case 'animations':
        return (<Animations anchor={''} handlePageClick={handlePageClick} />);
        break;
      case 'graphic-design':
        return (<GraphicDesign anchor={''} handlePageClick={handlePageClick} />);
        break;
      case 'about':
        return (<About anchor={''} handlePageClick={handlePageClick} />);
        break;
      case 'apps-in-dev':
        return (<WebApps anchor={id} handlePageClick={handlePageClick} />);
        break;
      case 'nodejs':
        return (<WebApps anchor={id} handlePageClick={handlePageClick} />);
        break;
      case 'other-apps':
        return (<WebApps anchor={id} handlePageClick={handlePageClick} />);
        break;
      case 'javascript-snippets':
        return (<WebApps anchor={id} handlePageClick={handlePageClick} />);
        break;
      case 'wordpress':
        return (<WebApps anchor={id} handlePageClick={handlePageClick} />);
        break;
      case 'actionscript':
        return (<WebApps anchor={id} handlePageClick={handlePageClick} />);
        break;
      case 'java':
        return (<WebApps anchor={id} handlePageClick={handlePageClick} />);
        break;
      case 'aia':
        return (<Websites anchor={id} handlePageClick={handlePageClick} />);
        break;
      case 'monash':
        return (<Websites anchor={id} handlePageClick={handlePageClick} />);
        break;
      case 'bhi':
        return (<Websites anchor={id} handlePageClick={handlePageClick} />);
        break;
      case 'yacmu':
        return (<Websites anchor={id} handlePageClick={handlePageClick} />);
        break;
      case 'misc-web-design':
        return (<Websites anchor={id} handlePageClick={handlePageClick} />);
        break;
      case 'st-columbs-church':
        return (<Animations anchor={id} handlePageClick={handlePageClick} />);
        break;
      case 'minecraft-worlds':
        return (<Animations anchor={id} handlePageClick={handlePageClick} />);
        break;
      case 'skydeck-alpha':
        return (<Animations anchor={id} handlePageClick={handlePageClick} />);
        break;
      case 'hyperion-gallery':
        return (<Animations anchor={id} handlePageClick={handlePageClick} />);
        break;
      case 'hyperion-marooned':
        return (<Animations anchor={id} handlePageClick={handlePageClick} />);
        break;
      case 'the-citadel':
        return (<Animations anchor={id} handlePageClick={handlePageClick} />);
        break;
      case 'plaza':
        return (<Animations anchor={id} handlePageClick={handlePageClick} />);
        break;
      case 'animated-clips':
        return (<Animations anchor={id} handlePageClick={handlePageClick} />);
        break;
      case 'flyers-brochures':
        return (<GraphicDesign anchor={id} handlePageClick={handlePageClick} />);
        break;
      case 'banners-logos':
        return (<GraphicDesign anchor={id} handlePageClick={handlePageClick} />);
        break;
      case 'illustrations':
        return (<GraphicDesign anchor={id} handlePageClick={handlePageClick} />);
        break;
      default:
        return ('unknown');
        break;
    }
  };

  const handlePageClick = (params: any) => {
    console.log('MainView.handlePageClick() called with', params);
    handlePageEvent(params);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ minHeight: '80vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          sx={{
            backgroundImage: 'url("./static/images/vine_pattern.jpg")',
            backgroundRepeat: 'repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'auto',
            backgroundPosition: 'center',
          }}
        >
          {getPage(pageID)}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default MainView;