import React, { FC, ReactElement, ReactNode } from 'react';
import { useState } from 'react';

import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

import Typography from '@mui/material/Typography';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import Copyright from '../footer/Copyright';

const theme = createTheme();

export interface HomeViewProps {
  pageID: string,
  handlePageEvent: Function,
}

const handleScroll = (itemID: string) => {
  console.log('handleScroll() called with ' + itemID);
  if (itemID && itemID !== '') {
    const element = document.getElementById(itemID);
    element?.scrollIntoView({ behavior: 'smooth' });
  } else {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }
};

const HomeView: FC<HomeViewProps> = ({ pageID, handlePageEvent }): ReactElement => {
  console.log('HomeView rendering ' + pageID);

  const handlePageClick = (params: any) => {
    console.log('HomeView.handlePageClick() called with', params);
    handlePageEvent(params);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ minHeight: '80vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          sx={{
            backgroundImage: 'url("./static/images/vine_pattern.jpg")',
            backgroundRepeat: 'repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'auto',
            backgroundPosition: 'center',
          }}
        >
          <Box sx={{
            width: '90%',
            maxWidth: '1280px',
            mt: '10px',
            mb: '20px',
            ml: 'auto',
            mr: 'auto',
            padding: '20px',
            pt: '60px',
            pb: '60px',
            backgroundColor: 'rgba(255,255,255,0.1)',
            borderRadius: '6px',
          }}>
            <img src="./static/images/portfolio/webdev-150x150.png" width="100px" height="100px" loading="lazy" />
            <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
              Tony Gillan - Web Developer & Creative Specialist
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center', mb: 3 }}>
              Bringing your vision to life through innovative web solutions and creative expression.
            </Typography>
            <Typography variant="h6" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
              Explore My Work
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center', maxWidth: '800px', m: '0 auto' }}>
              The following resources are examples of the applications, websites, designs, & animations that Tony has produced over his professional career.
            </Typography>
            <Box sx={{ pt: '30px' }}>
              <Link color="inherit" title="Web Applications" onClick={() => {handleScroll('web-applications');}}>
                <img src="./static/images/portfolio/settings-150x150.png" width="50px" height="50px" loading="lazy" />
              </Link>
              &nbsp;
              <Link color="inherit" title="Website Development" onClick={() => {handleScroll('website-development');}}>
                <img src="./static/images/portfolio/wordpress-100x100.png" width="50px" height="50px" loading="lazy" />
              </Link>
              &nbsp;
              <Link color="inherit" title="Graphic Design" onClick={() => {handleScroll('graphic-design');}}>
                <img src="./static/images/portfolio/adobe-100x100.png" width="50px" height="50px" loading="lazy" />
              </Link>
              &nbsp;
              <Link color="inherit" title="Animation and 3D Development" onClick={() => {handleScroll('animation');}}>
                <img src="./static/images/portfolio/games-100x100.png" width="50px" height="50px" loading="lazy" />
              </Link>
              &nbsp;
            </Box>
          </Box>
          <Box id='web-applications' sx={{
            width: '90%',
            maxWidth: '1280px',
            mt: '10px',
            mb: '20px',
            ml: 'auto',
            mr: 'auto',
            padding: '20px',
            pt: '60px',
            backgroundColor: 'rgba(255,255,255,0.1)',
            borderRadius: '6px',
          }}>
            <Box>
              <Link color="inherit" onClick={() => handlePageClick({ type: 'view-page', value: 'web-applications' })} style={{ cursor: 'pointer' }} title="Web Applications">
                <img src="./static/images/portfolio/settings.png" width="100px" height="100px" loading="lazy" />
              </Link>
            </Box>
            <Typography variant="h6" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
              Web Applications
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center', maxWidth: '800px', m: '0 auto' }}>
              Apps designed for the web developed using React.js, Next.js, Angular, Typescript, Javascript, HTML5, CSS3, and Node.js
            </Typography>
            <Box sx={{ maxWidth: '640px', ml: 'auto', mr: 'auto', pt: '30px' }}>
              <Link color="inherit" onClick={() => handlePageClick({ type: 'view-page', value: 'web-applications' })} style={{ cursor: 'pointer' }} title="Web Applications">
                <img src="./static/images/portfolio/midnightbbs_16_640_banner.jpg" width="100%" loading="lazy" />
              </Link>
            </Box>
            <Box sx={{ mt: '40px', mb: '20px' }} >
              <Link color="inherit" title="Back to the top" onClick={() => {handleScroll('');}}>
                <img src="./static/images/portfolio/up_arrow_gold.png" loading="lazy" />
              </Link>
            </Box>
          </Box>
          <Box id='website-development' sx={{
            width: '90%',
            maxWidth: '1280px',
            mt: '10px',
            mb: '20px',
            ml: 'auto',
            mr: 'auto',
            padding: '20px',
            pt: '60px',
            backgroundColor: 'rgba(255,255,255,0.1)',
            borderRadius: '6px',
          }}>
            <Box>
              <Link color="inherit" onClick={() => handlePageClick({ type: 'view-page', value: 'website-development' })} style={{ cursor: 'pointer' }} title="Website Development">
                <img src="./static/images/portfolio/wordpress.png" width="100px" height="100px" loading="lazy" />
              </Link>
            </Box>
            <Typography variant="h6" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
              CMS Website Development
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center', maxWidth: '800px', m: '0 auto' }}>
              Design and development projects for the web using WordPress and Magento (PHP backend, JavaScript & React.js frontend)
            </Typography>
            <Box sx={{ maxWidth: '800px', ml: 'auto', mr: 'auto', pt: '30px' }}>
              <Link color="inherit" onClick={() => handlePageClick({ type: 'view-page', value: 'web-aia' })} style={{ cursor: 'pointer' }} title="Australian Institute of Architects">
                <img src="./static/images/portfolio/panel_aia_960x220.jpg" width="100%" loading="lazy" />
              </Link>
              <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center', position: 'relative', top: '-50px', backgroundColor: 'rgba(0,0,0,0.8)', pt: '10px', pb: '10px', maxWidth: '800px', ml: 'auto', mr: 'auto' }}>
                Australian Institute of Architects
              </Typography>
            </Box>
            <Box sx={{ maxWidth: '800px', ml: 'auto', mr: 'auto' }}>
              <Link color="inherit" onClick={() => handlePageClick({ type: 'view-page', value: 'web-monash' })} style={{ cursor: 'pointer' }} title="Monash University">
                <img src="./static/images/portfolio/panel_monash_960x220.jpg" width="100%" loading="lazy" />
              </Link>
              <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center', position: 'relative', top: '-50px', backgroundColor: 'rgba(0,0,0,0.8)', pt: '10px', pb: '10px', maxWidth: '800px', ml: 'auto', mr: 'auto' }}>
                Monash University
              </Typography>
            </Box>
            <Box sx={{ maxWidth: '800px', ml: 'auto', mr: 'auto' }}>
              <Link color="inherit" onClick={() => handlePageClick({ type: 'view-page', value: 'web-bhi' })} style={{ cursor: 'pointer' }} title="Box Hill Institute of TAFE">
                <img src="./static/images/portfolio/panel_boxhill_960x220.jpg" width="100%" loading="lazy" />
              </Link>
              <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center', position: 'relative', top: '-50px', backgroundColor: 'rgba(0,0,0,0.8)', pt: '10px', pb: '10px' }}>
                Box Hill Institute of TAFE
              </Typography>
            </Box>
            <Box sx={{ maxWidth: '800px', ml: 'auto', mr: 'auto' }}>
              <Link color="inherit" onClick={() => handlePageClick({ type: 'view-page', value: 'web-yacmu' })} style={{ cursor: 'pointer' }} title="YACMU">
                <img src="./static/images/portfolio/panel_yacmu_960x220.jpg" width="100%" loading="lazy" />
              </Link>
              <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center', position: 'relative', top: '-50px', backgroundColor: 'rgba(0,0,0,0.8)', pt: '10px', pb: '10px', maxWidth: '800px', ml: 'auto', mr: 'auto' }}>
                YACMU
              </Typography>
            </Box>
            <Box sx={{ maxWidth: '800px', ml: 'auto', mr: 'auto' }}>
              <Link color="inherit" onClick={() => handlePageClick({ type: 'view-page', value: 'web-misc' })} style={{ cursor: 'pointer' }} title="Misc Web Projects">
                <img src="./static/images/portfolio/panel_misc_960x220.jpg" width="100%" loading="lazy" />
              </Link>
              <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center', position: 'relative', top: '-50px', backgroundColor: 'rgba(0,0,0,0.8)', pt: '10px', pb: '10px', maxWidth: '800px', ml: 'auto', mr: 'auto' }}>
                Misc Web Projects
              </Typography>
            </Box>
            <Box sx={{ mt: '0', mb: '20px' }} >
              <Link color="inherit" title="Back to the top" onClick={() => {handleScroll('');}}>
                <img src="./static/images/portfolio/up_arrow_gold.png" loading="lazy" />
              </Link>
            </Box>
          </Box>
          <Box id='graphic-design' sx={{
            width: '90%',
            maxWidth: '1280px',
            mt: '10px',
            mb: '20px',
            ml: 'auto',
            mr: 'auto',
            padding: '20px',
            pt: '60px',
            backgroundColor: 'rgba(255,255,255,0.1)',
            borderRadius: '6px',
          }}>
            <Box>
              <Link color="inherit" onClick={() => handlePageClick({ type: 'view-page', value: 'graphic-design' })} style={{ cursor: 'pointer' }} title="Graphic Design">
                <img src="./static/images/portfolio/adobe.png" width="100px" height="100px" loading="lazy" />
              </Link>
            </Box>
            <Typography variant="h6" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
              Graphic Design
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
              Design for print & web
            </Typography>
            <Box sx={{ maxWidth: '800px', ml: 'auto', mr: 'auto', pt: '30px' }}>
              <Link color="inherit" onClick={() => handlePageClick({ type: 'view-page', value: 'graphic-design' })} style={{ cursor: 'pointer' }} title="Graphic Design">
                <img src="./static/images/portfolio/panel_design2_960x220.jpg" width="100%" loading="lazy" />
              </Link>
            </Box>
            <Box sx={{ mt: '40px', mb: '20px' }} >
              <Link color="inherit" title="Back to the top" onClick={() => {handleScroll('');}}>
                <img src="./static/images/portfolio/up_arrow_gold.png" loading="lazy" />
              </Link>
            </Box>
          </Box>
          <Box id='animation' sx={{
            width: '90%',
            maxWidth: '1280px',
            mt: '10px',
            mb: '20px',
            ml: 'auto',
            mr: 'auto',
            padding: '20px',
            pt: '60px',
            backgroundColor: 'rgba(255,255,255,0.1)',
            borderRadius: '6px',
          }}>
            <Box>
              <Link color="inherit" onClick={() => handlePageClick({ type: 'view-page', value: 'animation' })} style={{ cursor: 'pointer' }} title="Animation and 3D Development">
                <img src="./static/images/portfolio/games.png" width="100px" height="100px" loading="lazy" />
              </Link>
            </Box>
            <Typography variant="h6" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
              Animation and 3D Development
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
              Models and virtual worlds produced using 3DS Max and Unreal Engine
            </Typography>
            <Box sx={{ maxWidth: '800px', ml: 'auto', mr: 'auto', pt: '30px' }}>
              <Link color="inherit" onClick={() => handlePageClick({ type: 'view-page', value: 'animation' })} style={{ cursor: 'pointer' }} title="Animation and 3D Development">
                <img src="./static/images/portfolio/panel_virtual_960x220.jpg" width="100%" loading="lazy" />
              </Link>
            </Box>
            <Box sx={{ mt: '40px', mb: '20px' }} >
              <Link color="inherit" title="Back to the top" onClick={() => {handleScroll('');}}>
                <img src="./static/images/portfolio/up_arrow_gold.png" loading="lazy" />
              </Link>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default HomeView;