import React, { FC, ReactElement } from 'react';
import { useState } from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import NearMeIcon from '@mui/icons-material/NearMe';
import LanguageIcon from '@mui/icons-material/Language';

import AdbIcon from '@mui/icons-material/Adb';

import Copyright from './Copyright';

export interface FooterBarProps {
  handleFooterUpdate: Function,
};

const FooterBar: FC<FooterBarProps> = ({ handleFooterUpdate }): ReactElement => {

  return (
    <Box sx={{ position: 'relative', bottom: '0', width: '100%' }}>
      <Box sx={{ pt: '20px', pb: '30px', backgroundColor: '#ccc' }}>
        <Typography variant="h6" color="text.secondary" align="center">
          Contact
        </Typography>
        <Typography variant="body2" color="text.secondary" align="center" sx={{ mb: '15px' }}>
          Tony Gillan<br />
          Melbourne, Australia<br />
          <Link color="inherit" href="mailto:info@tonygillan.com">info@tonygillan.com</Link>
        </Typography>
        <Typography variant="body2" color="text.secondary" align="center">
          View more information on:<br />
          <Link color="inherit" href="https://www.linkedin.com/in/tgillan" title="LinkedIn"><img src="./static/images/portfolio/linkedin.png" /></Link>&nbsp;
          <Link color="inherit" href="https://bitbucket.org/skylightcreative/workspace/repositories" title="Bitbucket"><img src="./static/images/portfolio/bitbucket_logo_30x30.png" /></Link>
        </Typography>
      </Box>
      <Box sx={{ pt: '20px', pb: '30px', backgroundColor: '#333' }}>
        <Copyright sx={{color: '#fff'}} />
      </Box>
    </Box>
  );
}

export default FooterBar;
