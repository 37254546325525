import React, { FC, ReactElement } from 'react';
import { useState } from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import NearMeIcon from '@mui/icons-material/NearMe';
import LanguageIcon from '@mui/icons-material/Language';

import AdbIcon from '@mui/icons-material/Adb';

import MenuBar from './MenuBar';

import { MenuItemProps, MenuListProps, LinkImageProps, LinkTextProps } from './interfaces';

const userMenuItems: MenuListProps = {
  id: 'test',
  itemList: [],
};

export interface MenuUpdateProps {
  type: string
  value: string,
};

export interface HeaderBarCallProps {
  topMenuItems: MenuItemProps[],
  menuItemMap: Map<string, MenuItemProps[]>,
  handleHeaderUpdate: Function,
};

const HeaderBar: FC<HeaderBarCallProps> = ({ topMenuItems, menuItemMap, handleHeaderUpdate }): ReactElement => {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: any) => {
    console.log('HeaderBar.handleOpenUserMenu() called with ' + event.target.value);
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    console.log('HeaderBar.handleCloseUserMenu() called.');
    setAnchorElUser(null);
  };

  const handleMenuUpdate = (updateObj: MenuUpdateProps) => {
    console.log('HeaderBar.handleMenuUpdate() called.');
    switch (updateObj.type) {
      case 'menu_item_click':
        handleHeaderUpdate(updateObj);
        break;
      default:
        break;
    }
  };

  const handleTopMenuItemClick = (title: string) => {
    console.log('HeaderBar.handleTopMenuItemClick() called with ' + title);
    switch (title) {
      case 'lbh':
        handleHeaderUpdate({
          type: 'link_click',
          value: 'https://listeningbeyondhearing.com.au',
        });
        break;
      case 'art':
        handleHeaderUpdate({
          type: 'link_click',
          value: 'https://tonygillan.au',
        });
        break;
      case 'skylight':
        handleHeaderUpdate({
          type: 'link_click',
          value: 'https://skylightcreative.com.au',
        });
        break;
      case 'idle':
        handleHeaderUpdate({
          type: 'link_click',
          value: 'https://idlegarden.com',
        });
        break;
      default:
        break;
    }
  };

  //Right Button Section (large screen only)
  const RightButtonSectionLarge = () => {
    return (
      <Box sx={{ mt: '0', mr: '16px' }}>
        <Typography variant="h6" noWrap sx={{
          display: { xs: 'none', md: 'block' },
          color: '#777',
          textDecoration: 'none',
          fontSize: '1.0rem',
          textAlign: 'right',
        }} >
          <Box sx={{ position: 'relative', top: '-10px' }}>
            <Button size="small" title="Application and Resource Development" onClick={() => handleTopMenuItemClick('skylight')} sx={{ my: 2, color: '#eee', display: 'inline-block', textTransform: 'none', }}>Skylight Creative</Button>
            |
            <Button size="small" title="LBH - Music Education Resources" onClick={() => handleTopMenuItemClick('lbh')} sx={{ my: 2, color: '#eee', display: 'inline-block', textTransform: 'none', }}>Listening Beyond Hearing</Button>
            |
            <Button size="small" title="Idle Garden - App Hosting" onClick={() => handleTopMenuItemClick('idle')} sx={{ my: 2, color: '#eee', display: 'inline-block', textTransform: 'none', }}>Idle Garden</Button>
            |
            <Button size="small" title="Tony Gillan - Art Gallery" onClick={() => handleTopMenuItemClick('art')} sx={{ my: 2, color: '#eee', display: 'inline-block', textTransform: 'none', }}>Art Gallery</Button>
          </Box>
        </Typography>
      </Box>
    )
  };

  //Middle Logo Section (large screen only)
  const MiddleLogoSectionLarge = () => {
    return (
      <Box sx={{ mr: 'auto', ml: 'auto', mt: '-30px' }}>
        <Typography variant="h4" noWrap component="a" href="/" sx={{
          display: { xs: 'none', md: 'block' },
          fontFamily: 'noto sans,sans-serif',
          fontWeight: 700,
          letterSpacing: '.3rem',
          color: 'rgba(255,255,255,0.7)',
          textDecoration: 'none',
          backgroundColor: 'none',
          textAlign: 'center',
        }} >
          Tony Gillan
        </Typography>
        <Typography variant="h6" noWrap component="a" href="/" sx={{
          display: { xs: 'none', md: 'block' },
          fontFamily: 'noto sans,sans-serif',
          fontWeight: 700,
          letterSpacing: '.3rem',
          color: '#bbb',
          textDecoration: 'none',
          backgroundColor: 'none',
          textAlign: 'center',
        }} >
          Web Application & Resource Development
        </Typography>
      </Box>
    )
  };

  //Main Menu Bar
  const MainMenuBar = () => {
    return (
      <Container maxWidth="xl" sx={{ backgroundColor: 'none', color: '#000' }}>
        <Toolbar disableGutters sx={{ backgroundColor: 'none', color: '#000' }}>
          {/* Main Menu Bar (small and large screens) */}
          <MenuBar topMenuItems={topMenuItems} subMenuItems={menuItemMap} handleMenuUpdate={handleMenuUpdate} />
        </Toolbar>
      </Container>
    )
  };

  return (
    <AppBar position="static" sx={{
      backgroundColor: '#2e2406',
      backgroundImage: 'url("./static/images/portfolio/skylight-banner-scrum.jpg")',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      mr: 'auto',
      ml: 'auto',
    }}>
      {/* Top Banner with Left & Right Button Sections (large screen only) */}
      {/* Right Button Section (large screen only) */}
      <RightButtonSectionLarge />
      {/* Middle Logo Section (large screen only) */}
      <MiddleLogoSectionLarge />
      {/* Main Menu Bar */}
      <MainMenuBar />
    </AppBar>
  );
}

export default HeaderBar;
