import React, { FC, ReactElement, ReactNode } from 'react';
import { useState } from 'react';

import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

import Typography from '@mui/material/Typography';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { url } from 'inspector';

import ListBlock from './ListBlock';

const theme = createTheme();

export interface WebAppsProps {
  anchor: string,
  handlePageClick: Function,
}

const showAnchor = (anchor: string) => {
  switch (anchor) {
    case 'web-apps':
      return (<>Main Test</>);
      break;
    case 'websites':
      return (<>Main Test</>);
      break;
    case 'animations':
      return (<>Main Test</>);
      break;
    case 'graphic-design':
      return (<>Main Test</>);
      break;
    case 'articles':
      return (<>Main Test</>);
      break;
    case 'about':
      return (<>Main Test</>);
      break;
    case 'apps-in-dev':
      return (<>Main Test</>);
      break;
    case 'nodejs':
      return (<>Main Test</>);
      break;
    case 'other-apps':
      return (<>Main Test</>);
      break;
    case 'javascript-snippets':
      return (<>Main Test</>);
      break;
    case 'wordpress':
      return (<>Main Test</>);
      break;
    case 'actionscript':
      return (<>Main Test</>);
      break;
    case 'java':
      return (<>Main Test</>);
      break;
    case 'aia':
      return (<>Main Test</>);
      break;
    case 'monash':
      return (<>Main Test</>);
      break;
    case 'bhi':
      return (<>Main Test</>);
      break;
    case 'yacmu':
      return (<>Main Test</>);
      break;
    case 'misc-web-design':
      return (<>Main Test</>);
      break;
    case 'st-columbs-church':
      return (<>Main Test</>);
      break;
    case 'minecraft-worlds':
      return (<>Main Test</>);
      break;
    case 'skydeck-alpha':
      return (<>Main Test</>);
      break;
    case 'hyperion-gallery':
      return (<>Main Test</>);
      break;
    case 'hyperion-marooned':
      return (<>Main Test</>);
      break;
    case 'the-citadel':
      return (<>Main Test</>);
      break;
    case 'plaza':
      return (<>Main Test</>);
      break;
    case 'animated-clips':
      return (<>Main Test</>);
      break;
    case 'flyers-brochures':
      return (<>Main Test</>);
      break;
    case 'banners-logos':
      return (<>Main Test</>);
      break;
    case 'illustrations':
      return (<>Main Test</>);
      break;
    case 'home':
    default:
      return (<>Main Test</>);
      break;
  }
};

const handleListBlockClick = (item: string) => {
  //#todo
};


const handleScroll = (itemID: string) => {
  console.log('handleScroll() called with ' + itemID);
  if (itemID && itemID !== '') {
    const element = document.getElementById(itemID);
    element?.scrollIntoView({ behavior: 'smooth' });
  } else {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }
};

const WebApps: FC<WebAppsProps> = ({ anchor, handlePageClick }): ReactElement => {

  return (
    <>
      <Box id='website-applications' sx={{
        width: '90%',
        maxWidth: '1280px',
        mt: '10px',
        mb: '20px',
        ml: 'auto',
        mr: 'auto',
        padding: '20px',
        pt: '60px',
        pb: '60px',
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderRadius: '6px',
      }}>
        <Typography variant="h4" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          Web Applications
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          A number of applications & code snippets developed for the web using JavaScript with React, Angular, Bootstrap, Node.js, SQL Server, WordPress, PHP, Java, and Actionscript. To view the source code of these and other applications, visit my:
        </Typography>
        <List sx={{ width: '100%', maxWidth: 480, bgcolor: 'rgba(255,255,255,0.8)', borderRadius: '5px', ml: "auto", mr: "auto" }}>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt="Bitbucket" src="./static/images/portfolio/bitbucket.png" />
            </ListItemAvatar>
            <ListItemText
              primary={<Link href="https://bitbucket.org/skylightcreative/workspace/repositories" target="_blank" underline="hover">Git Repositories</Link>}
              secondary="a collection of projects from my Bitbucket repositories"
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt="JSFiddle" src="./static/images/portfolio/jsfiddle.png" />
            </ListItemAvatar>
            <ListItemText
              primary={<Link href="https://jsfiddle.net/u/tgillan/fiddles/" target="_blank" underline="hover">JSFiddle code snippets</Link>}
              secondary="a collection of code snippets from my JSFiddle account"
            />
          </ListItem>
        </List>
        <Box sx={{ pt: '30px' }}>
          <Link color="inherit" title="Apps in Development" onClick={() => {handleScroll('apps-in-dev');}}>
            <img src="./static/images/portfolio/settings-150x150.png" width="50px" height="50px" loading="lazy" />
          </Link>
          &nbsp;
          <Link color="inherit" title="JavaScript" onClick={() => {handleScroll('javascript');}}>
            <img src="./static/images/portfolio/jsfiddle_logo-100x100.png" width="50px" height="50px" loading="lazy" />
          </Link>
          &nbsp;
          <Link color="inherit" title="WordPress" onClick={() => {handleScroll('wordpress');}}>
            <img src="./static/images/portfolio/wordpress-100x100.png" width="50px" height="50px" loading="lazy" />
          </Link>
          &nbsp;
          <Link color="inherit" title="Actionscript" onClick={() => {handleScroll('actionscript');}}>
            <img src="./static/images/portfolio/adobe-flash-8-logo-150x150.png" width="50px" height="50px" loading="lazy" />
          </Link>
          &nbsp;
          <Link color="inherit" title="Java" onClick={() => {handleScroll('java');}}>
            <img src="./static/images/portfolio/java_logo.png" width="50px" height="50px" loading="lazy" />
          </Link>
        </Box>
      </Box>
      <Box id='websites' sx={{
        width: '90%',
        maxWidth: '1280px',
        mt: '10px',
        mb: '20px',
        ml: 'auto',
        mr: 'auto',
        padding: '20px',
        pt: '60px',
        pb: '60px',
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderRadius: '6px',
      }}>
        <Typography variant="h5" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          Web Apps in Production
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          Demonstrations of SPA and multi-page websites that are live in current production.<br />
          Includes React.js + Next.js + JavaScript + Node.js + MongoDB + MySQL hosted sites.
        </Typography>
        <List sx={{ width: '100%', maxWidth: 480, bgcolor: 'rgba(255,255,255,0.8)', borderRadius: '5px', ml: "auto", mr: "auto", mt: "20px" }}>
          <ListItem alignItems="flex-start">
            <Box sx={{ width: '100px', mr: '15px', textAlign: 'center' }}><Link href="https://tonygillan.com/" target="_blank"><img src="./static/images/portfolio/logo192.png" width="60px" loading="lazy" /></Link></Box>
            <ListItemText
              primary={<Link href="https://tonygillan.com/" target="_blank" underline="hover">Tony Gillan - Development Portfolio</Link>}
              secondary="Portfolio SPA built in React.js hosted in Docker"
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <Box sx={{ width: '100px', mr: '15px', textAlign: 'center' }}><Link href="https://tonygillan.au/" target="_blank"><img src="./static/images/portfolio/art_studio_01_150.png" width="60px" loading="lazy" /></Link></Box>
            <ListItemText
              primary={<Link href="https://tonygillan.au/" target="_blank" underline="hover">Tony Gillan - Art Gallery</Link>}
              secondary="Gallery multi-page site built in Next.js hosted in Docker"
            />
          </ListItem>
        </List>
        <Box sx={{ mt: '40px', mb: '20px' }} >
          <Link color="inherit" title="Back to the top" onClick={() => {handleScroll('');}}>
            <img src="./static/images/portfolio/up_arrow_gold.png" />
          </Link>
        </Box>
      </Box>
      <Box id='apps-in-dev' sx={{
        width: '90%',
        maxWidth: '1280px',
        mt: '10px',
        mb: '20px',
        ml: 'auto',
        mr: 'auto',
        padding: '20px',
        pt: '60px',
        pb: '60px',
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderRadius: '6px',
      }}>
        <Typography variant="h5" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          Web Apps in Development
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          <Link href="https://idlegarden.com" target="_blank" title="Development Apps"><img className="rounded" src="./static/images/portfolio/idlegarden_logo_web_med.png" loading="lazy" /></Link>
          <br />
          Demonstrations of web-apps and virtual worlds that are in current or past development.<br />
          Includes React.js + Angular + JavaScript + Node.js + MongoDB + MySQL hosted sites.
        </Typography>
        <List sx={{ width: '100%', maxWidth: 480, bgcolor: 'rgba(255,255,255,0.8)', borderRadius: '5px', ml: "auto", mr: "auto", mt: "20px" }}>
          <ListItem alignItems="flex-start">
            <Box sx={{ width: '100px', mr: '15px', textAlign: 'center' }}><Link href="https://europa.idlegarden.com/midnight" target="_blank"><img src="./static/images/portfolio/midnight_rgb-100.png" width="100px" loading="lazy" /></Link></Box>
            <ListItemText
              primary={<Link href="https://europa.idlegarden.com/midnight" target="_blank" underline="hover">Midnight Server</Link>}
              secondary="Various React &amp; Angular Web apps and snippets hosted on a Node.js backend"
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <Box sx={{ width: '100px', mr: '15px', textAlign: 'center' }}><Link href="https://europa.idlegarden.com/node" target="_blank"><img src="./static/images/portfolio/nodejs_logo_sm.png" width="100px" loading="lazy" /></Link></Box>
            <ListItemText
              primary={<Link href="https://europa.idlegarden.com/node" target="_blank" underline="hover">Skylight API Server</Link>}
              secondary="Database API &amp; general file server built in Node.js with React front-end"
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <Box sx={{ width: '100px', mr: '15px', textAlign: 'center' }}><Link href="https://echocert.com/" target="_blank"><img src="./static/images/portfolio/echocert_sm.png" width="60px" loading="lazy" /></Link></Box>
            <ListItemText
              primary={<Link href="https://echocert.com/" target="_blank" underline="hover">echo cert</Link>}
              secondary="Block Chain application prototypes"
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <Box sx={{ width: '100px', mr: '15px', textAlign: 'center' }}><Link href="https://orbitaldrift.com/" target="_blank"><img src="./static/images/portfolio/orbital-sm.png" width="100px" loading="lazy" /></Link></Box>
            <ListItemText
              primary={<Link href="https://orbitaldrift.com/" target="_blank" underline="hover">orbital drift</Link>}
              secondary="Unreal Engine + Minecraft hosted virtual worlds"
            />
          </ListItem>
        </List>
        <Box sx={{ mt: '40px', mb: '20px' }} >
          <Link color="inherit" title="Back to the top" onClick={() => {handleScroll('');}}>
            <img src="./static/images/portfolio/up_arrow_gold.png" />
          </Link>
        </Box>
      </Box>
      <Box id='javascript' sx={{
        width: '90%',
        maxWidth: '1280px',
        mt: '10px',
        mb: '20px',
        ml: 'auto',
        mr: 'auto',
        padding: '20px',
        pt: '60px',
        pb: '60px',
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderRadius: '6px',
      }}>
        <Typography variant="h5" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          JavaScript Code Snippets
        </Typography>
        <Box sx={{ maxWidth: '500px', ml: 'auto', mr: 'auto', bgcolor: 'rgba(0,0,0,0.3)', padding: '20px', borderRadius: '5px' }}>
          <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'left', }}>
            <Link title="JSFiddle code snippets" href="https://jsfiddle.net/u/tgillan/fiddles/" target="_blank"><img decoding="async" src="./static/images/portfolio/jsfiddle_logo.png" width="50" loading="lazy" /></Link>
            <h4>Frameworks</h4>
            <ul>
              <li><Link href="https://jsfiddle.net/tgillan/469uL5jc/" target="_blank" underline="hover">Script 52 - D3 v5 Library Test</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/p74Lrjc5/" target="_blank" underline="hover">Script 51 - Jasmine Test</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/zr0ksLxa/" target="_blank" underline="hover">Script 50 - Jasmine Unit Test</Link></li>
              <li><Link href="">Script 47 - React File Manager</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/namu81sr/" target="_blank" underline="hover">Script 45 - React JSX ToDo List</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/4psLvnt6/" target="_blank" underline="hover">Script 44 - React Test 2</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/urgma29n/" target="_blank" underline="hover">Script 41 - Comparision of ES5 ES2015 Typescript</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/hmo1jvfz/" target="_blank" underline="hover">Script 40 - Mock Database Service</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/1az3ghvd/" target="_blank" underline="hover">Script 39 - JavaScript Modular Pattern Template</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/xw56nq8k/" target="_blank" underline="hover">Script 31 - JavaScript Modular Pattern Template</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/034ecwn6/" target="_blank" underline="hover">Script 11 - React Redux Test 1</Link> - A demo demonstrating using Redux with React. Uses React, Redux, and React-Redux. Uses babel to handle some ES6 syntax, and Lodash for some syntactic sugar.</li>
              <li><Link href="https://jsfiddle.net/tgillan/g1zs2a9f/" target="_blank" underline="hover">Script 09 - State Manager</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/3m5qg9dw/" target="_blank" underline="hover">Script 02 - React Test 1</Link></li>
            </ul>
            <h4>jQuery</h4>
            <ul>
              <li><Link href="https://jsfiddle.net/tgillan/j3keshn9/" target="_blank" underline="hover">Script 20 - jQuery Data onChange Event</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/Lgtar86b/" target="_blank" underline="hover">Script 10 - jQuery Class Change</Link></li>
            </ul>
            <h4>Mathematical Algorithms</h4>
            <ul>
              <li><Link href="https://jsfiddle.net/tgillan/24d7py3o/" target="_blank" underline="hover">Script 38 - Base64 Number To Text 2</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/08wgjxy3/" target="_blank" underline="hover">Script 26 - Base64 Number to Text</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/9wp3hvto/" target="_blank" underline="hover">Script 22 - Breadth First Search</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/L0owpu39/" target="_blank" underline="hover">Script 01a - Fibonacci</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/4okya3cd/" target="_blank" underline="hover">Script 01 - Fibonacci</Link> - pure Javascript Fibonacci sequence test</li>
            </ul>
            <h4>Strings</h4>
            <ul>
              <li><Link href="https://jsfiddle.net/tgillan/54qduh76/" target="_blank" underline="hover">Script 49 - String Functions</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/gw012yn4/" target="_blank" underline="hover">Script 47 - String Parser</Link></li>
            </ul>
            <h4>Lists and Arrays</h4>
            <ul>
              <li><Link href="https://jsfiddle.net/tgillan/4oxkbtne/" target="_blank" underline="hover">Script 46 - List Split Into MidPoints</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/wLceujzy/" target="_blank" underline="hover">Script 43 - Functional Immutable Array Operations</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/fx21a3uo/" target="_blank" underline="hover">Script 42 - Sorting Algorithms</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/51aLo8f0/" target="_blank" underline="hover">Script 37 - Single Lock Queue</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/ownfr8p4/" target="_blank" underline="hover">Script 35 - Simple List to XML 2</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/yokxmasv/" target="_blank" underline="hover">Script 33 - Array Split</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/owne8yzp/" target="_blank" underline="hover">Script 32 - Linked Binary Search Tree</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/2sdvjp3k/" target="_blank" underline="hover">Script 28 - Index To Alpha</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/Lruy75sk/" target="_blank" underline="hover">Script 23 - Simple List To XML</Link></li>
            </ul>
            <h4>Objects</h4>
            <ul>
              <li><Link href="https://jsfiddle.net/tgillan/p2uwbxte/" target="_blank" underline="hover">Script 36 - Deep Clone</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/rzadLbhk/" target="_blank" underline="hover">Script 24 - Deep Clone</Link></li>
            </ul>
            <h4>Networking</h4>
            <ul>
              <li><Link href="https://jsfiddle.net/tgillan/t31mov76/" target="_blank" underline="hover">Script 54 - Self-Regulating Interval Timer</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/aumo3qpe/" target="_blank" underline="hover">Script 29 - Node Network</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/rw3tLo46/" target="_blank" underline="hover">Script 27 - Node Server</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/06egrmao/" target="_blank" underline="hover">Script 25 - Single Lock Queue</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/21Lnc5k6/" target="_blank" underline="hover">Script 34 - Network Path</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/xw2yg61a/" target="_blank" underline="hover">Script 30 - Priority Message Queue</Link></li>
            </ul>
            <h4>Gaming</h4>
            <ul>
              <li><Link href="https://jsfiddle.net/tgillan/hw1v0mz7/" target="_blank" underline="hover">Script 53 - E3 - Engine Simulator</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/b13f6c9q/" target="_blank" underline="hover">Script 21 - Graph Manager</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/L73p9bdr/" target="_blank" underline="hover">Script 19 - Search Class</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/pabv4u3f/" target="_blank" underline="hover">Script 18 - Find Paths Test</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/ykf6wna8/" target="_blank" underline="hover">Script 17 - Grid Class</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/g9m1y2vL/" target="_blank" underline="hover">Script 16 - Lander Test</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/sy8znm5q/" target="_blank" underline="hover">Script 15 - Ship Class</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/c8r7fevj/" target="_blank" underline="hover">Script 14 - Item Tree Hash Map</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/zakctsvn/" target="_blank" underline="hover">Script 13 - World Manager</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/onsmxbg3/" target="_blank" underline="hover">Script 12 - Node Manager</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/rpxf2jkm/" target="_blank" underline="hover">Script 03 - Person Class</Link></li>
            </ul>
            <h4>Misc</h4>
            <ul>
              <li><Link href="https://jsfiddle.net/tgillan/4ko1gcvf/" target="_blank" underline="hover">Script 08 - SCSS Test 2</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/15fxywdn/" target="_blank" underline="hover">Script 07 - SCSS Test 1</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/7u8ztmc9/" target="_blank" underline="hover">Script 06 - Canvas Test</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/zgcfh849/" target="_blank" underline="hover">Script 05 - Filter Int Testing</Link></li>
              <li><Link href="https://jsfiddle.net/tgillan/zdyfjbk0/" target="_blank" underline="hover">Script 04 - Filter Int</Link></li>
            </ul>
          </Typography>
        </Box>
        <Box sx={{ mt: '40px', mb: '20px' }} >
          <Link color="inherit" title="Back to the top" onClick={() => {handleScroll('');}}>
            <img src="./static/images/portfolio/up_arrow_gold.png" loading="lazy" />
          </Link>
        </Box>
      </Box>

      <ListBlock anchor='wordpress' title='WordPress Themes &amp; Plugins'
        description={[
          { type: 'text', value: 'These applications were developed at Monash University and Australian Institute of Architects.' },
          { type: 'break' },
          { type: 'text', value: 'Please visit my ' },
          { type: 'link', url: 'https://bitbucket.org/skylightcreative/workspace/projects/WP', title: 'Git', value: 'Git archives' },
          { type: 'text', value: ' to view the source code of these and other applications developed during this period.' },
        ]}
        itemList={[
          {
            title: 'Australian Institute of Architects',
            description: 'WordPress themes developed for the main AIA site and various event sub-sites',
            imageSource: './static/images/portfolio/awards_2016_thumb-300x225.jpg',
            url: 'https://bitbucket.org/skylightcreative/aia-wordpress',
            target: '_blank',
          },
          {
            title: 'Monash University - Faculty of Arts',
            description: 'WordPress themes developed for main Faculty site and various sub-sites',
            imageSource: './static/images/portfolio/ug_screen_01-1-300x230.jpg',
            url: 'https://bitbucket.org/skylightcreative/arts-wordpress',
            target: '_blank',
          },
        ]}
        handleListBlockClick={handleListBlockClick} />

      <ListBlock anchor='actionscript' title='Actionscript Applications'
        description={[
          { type: 'text', value: 'These applications were developed while working with YACMU and later with the Box Hill Institute (2003-2011). Adobe Flash Actionscript was my primary development language during this time. HTML5, CSS3, and JavaScript become more prevalent in use after support for Flash was gradually dropped from the web.' },
        ]}
        itemList={[
          {
            title: 'UCKids Online',
            description: 'A video & multimedia conferencing app written Flash & Adobe Media Server allowing up to 10 clients and a moderator to teach using powerpoints, videos, and a whiteboard. Text & video-based chat are all built in.',
            imageSource: './static/images/portfolio/flash/ucconference_011-1-150x150.jpg',
            url: '#',
            target: '_self',
          },
          {
            title: 'Enhance Builder',
            description: 'A Flash-based presentation creation wizard. 30 video clip templates could have text overlays modified by the end-user.',
            imageSource: './static/images/portfolio/flash/builder-150x150.jpg',
            url: '#',
            target: '_self',
          },
          {
            title: 'Enhance',
            description: 'An interface to select and create playlists from over 300 short Flash multimedia clips written in Adobe Flash.',
            imageSource: './static/images/portfolio/flash/enhance_dvd_slip-1-150x150.jpg',
            url: '#',
            target: '_self',
          },
          {
            title: 'CAMS Simulator',
            description: 'A Flash-based application developed for CAMS for training of officials in flagging and accidents.',
            imageSource: './static/images/portfolio/flash/cams07-150x150.jpg',
            url: 'https://vimeo.com/174189884',
            target: '_blank',
          },
        ]}
        handleListBlockClick={handleListBlockClick} />

      <ListBlock anchor='java' title='Java Applications'
        description={[
          { type: 'text', value: 'These applications were developed at Griffith University, Bachelor of IT (Computing Science) (1999-2002). Primarily in Java, other applications were created in C, Prolog, Haskell, and LaTex.' },
          { type: 'break' },
          { type: 'text', value: 'Please visit my ' },
          { type: 'link', url: 'https://bitbucket.org/skylightcreative/workspace/projects/GC', title: 'Git', value: 'Git archives' },
          { type: 'text', value: ' to view the source code of these and other applications developed during this period.' },
        ]}
        itemList={[
          {
            title: 'Gnutella Lite',
            description: 'An implementation of the Gnutella P2P file sharing protocol written in Java.',
            imageSource: './static/images/portfolio/gu/gnutella_thumbnail.jpg',
            url: 'https://bitbucket.org/skylightcreative/gnutella/',
            target: '_blank',
          }, {
            title: 'HyperWord',
            description: 'A Java-based language translation system based on multiple patterns - sentence, phrase, and dictionary word. This implementation converts between English and German.',
            imageSource: './static/images/portfolio/gu/hyperword_thumbnail.jpg',
            url: 'https://bitbucket.org/skylightcreative/hyperword/',
            target: '_blank',
          },
        ]}
        handleListBlockClick={handleListBlockClick} />
    </>
  )
};

export default WebApps;